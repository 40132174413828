import React, { useEffect, useState } from 'react';
import { VolumeDown } from '@mui/icons-material';
import Slider from '@mui/material/Slider/Slider';
import { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { createUseStyles } from 'react-jss';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { toTimestamp } from '../../../utils/convertions';

interface IAudioPlayerProps {
  audioSrc: string;
  duration: number | null;
  handleSetVolume: (n: number) => void;
}

export default observer(function AudioPlayer({ audioSrc, duration, handleSetVolume }: IAudioPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [time, setTime] = useState(0);
  const [timeString, setTimeString] = useState('00:00');
  const audioEl = React.createRef<HTMLAudioElement>();
  const theme = useTheme();

  const onPlayToggle = () => {
    if (audioSrc.length < 1) return;
    if (audioEl.current) {
      if (audioEl.current.paused) {
        audioEl.current.play();
        setIsPlaying(true);
      } else {
        audioEl.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const onTimeCommitted = (event: any, aValue: number | number[]) => {
    if (audioSrc.length < 1) return;
    if (audioEl.current) {
      audioEl.current.currentTime = ((aValue as number) / 100) * audioEl.current.duration;
    }
  };

  const onTimeChange = (event: Event, aValue: number | number[]) => {
    if (audioSrc.length < 1) return;
    if (audioEl.current) {
      audioEl.current.pause();
      audioEl.current.currentTime = ((aValue as number) / 100) * audioEl.current.duration;
      setTime(audioEl.current.currentTime);
      setIsPlaying(false);
    }
  };

  const onVolumeChange = (event: Event, aValue: number | number[]) => {
    if (audioEl.current) {
      audioEl.current.volume = (aValue as number) / 100;
      setVolume(aValue as number);
      handleSetVolume(aValue as number);
    }
  };

  const onEnded = () => {
    if (audioEl.current) {
      setIsPlaying(false);
      audioEl.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (audioEl.current) {
      audioEl.current.volume = 0.5;
    }
  });

  useEffect(() => {
    const timeUpdate = (event: any) => {
      const time = (event.srcElement.currentTime / event.srcElement.duration) * 100;
      setTimeString(toTimestamp(event.srcElement.currentTime));
      setTime(isNaN(time) ? 0 : time);
    };
    if (audioEl.current) {
      audioEl.current.addEventListener('timeupdate', timeUpdate, { passive: false });
    }
    return () => {
      if (audioEl.current) {
        audioEl.current.removeEventListener('timeupdate', timeUpdate);
      }
    };
  }, []);

  const useStyles = createUseStyles((theme: Theme) => ({
    audioPlayer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0),
      boxSizing: 'border-box',
    },
    controlsContainer: {
      height: 30,
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      paddingRight: 10,
      paddingLeft: 7,
    },
    volumeContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 8,
      gap: 10,
      marginLeft: '-5px',
    },
    slider: {
      '&:before': {
        position: 'absolute',
        top: 13,
        left: -6,
        content: '""',
        width: 11,
        height: 2,
        backgroundColor: '#eeeeee',
      },
      '&:after': {
        position: 'absolute',
        top: 13,
        right: -9,
        content: '""',
        width: 11,
        height: 2,
        backgroundColor: '#6e6e6e',
      },
    },
  }));
  const classes = useStyles({ theme });

  return (
    <>
      <audio ref={audioEl} src={audioSrc} onEnded={onEnded} />
      <div className={classes.audioPlayer}>
        <div className={classes.controlsContainer}>
          <Slider className={classes.slider} color={'slider'} aria-label='Time slider' min={0} max={100} value={time} size={'small'} onChange={onTimeChange} onChangeCommitted={onTimeCommitted} />
        </div>
        <div style={{ maxWidth: 280, paddingLeft: 0 }}>
          <div className={classes.volumeContainer}>
            <IconButton color='gray' onClick={onPlayToggle} style={{ padding: 0 }}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <VolumeDown />
            <Slider color={'slider'} aria-label='Volume slider' min={0} max={100} value={volume} size={'small'} onChange={onVolumeChange} />

            <div style={{ minWidth: 100 }}>
              {timeString} / {toTimestamp(duration ?? 0)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
