import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const useConfirm = (title: string, message: string, warning?: boolean): [React.FC, () => Promise<boolean>] => {
  const theme = useTheme();
  const [promise, setPromise] = useState<{ resolve: (value: boolean) => void } | null>(null);

  const confirm = () =>
    new Promise<boolean>((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  // You could replace the Dialog with your library's version
  const ConfirmationDialog: React.FC = () => (
    <Dialog
      open={promise !== null}
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          /* backgroundColor: backgroundColor */
        },
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: warning ? theme.spacing(4) : 'unset' }}>
        {warning && <WarningAmberRoundedIcon color={'error'} sx={{ fontSize: 50 }} />}
        <DialogTitle>{title}</DialogTitle>
      </div>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'end', gap: theme.spacing(4), padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}` }}>
        <Button onClick={handleConfirm} variant='newSecondary' style={{ width: 90 }}>
          Yes
        </Button>
        <Button onClick={handleCancel} variant='newSecondary' style={{ width: 90 }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default useConfirm;
