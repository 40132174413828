import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import ListItem from '@mui/material/ListItem/ListItem';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar/ListItemAvatar';
import MovingIcon from '@mui/icons-material/Moving';
import IconButton from '@mui/material/IconButton/IconButton';
import Typography from '@mui/material/Typography/Typography';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SnackbarUtils from '../snackbarUtils/SnackbarUtilsConfigurator';
import { Theme, useTheme } from '@mui/material/styles';
import { useStore } from '../../store/store';
import { useAuth0 } from '@auth0/auth0-react';

interface IWalkthroughSettingsProps {
  isDialogOpen: boolean;
  setIsDialogOpen: () => void;
}

export default function WalkthroughSettings({ isDialogOpen, setIsDialogOpen }: IWalkthroughSettingsProps) {
  const [hasInteracted, setHasInteracted] = useState(false);
  const { userStore } = useStore();
  const { setCreateCourseWalkthrough } = userStore;
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const handleResetWalkthrough = () => {
    setCreateCourseWalkthrough(false).then(async () => {
      await getAccessTokenSilently({ cacheMode: 'off' });
      SnackbarUtils.info('Course Creation Guide is reset.');
    });
    setHasInteracted(true);
  };

  const handleCloseSettings = () => {
    if (hasInteracted) {
      location.reload();
    } else {
      setIsDialogOpen();
    }
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseSettings}
        maxWidth='sm'
        fullWidth
        PaperProps={{
          style: {
            background: theme.palette.bgTwo.main,
          },
        }}
      >
        <DialogTitle style={{ margin: 0, padding: theme.spacing(3), display: 'flex', alignItems: 'center' }}>
          <ModelTrainingIcon style={{ fontSize: 50, marginRight: theme.spacing(2) }} />
          <Typography variant='body1' fontSize={23} fontWeight={'bold'}>
            Interactive Guides
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseSettings}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List dense={false}>
            <ListItem
              secondaryAction={
                <Button aria-label='reset' color='secondary' onClick={handleResetWalkthrough}>
                  RESET
                </Button>
              }
            >
              <ListItemAvatar>
                <MovingIcon style={{ fontSize: '45' }} />
              </ListItemAvatar>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='body1' fontWeight={'bold'}>
                  Course Creation Guide
                </Typography>
                <Typography variant='subtitle2' fontWeight={'normal'}>
                  Click to reset the Course Creation Guide.
                </Typography>
              </div>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettings}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
