import { colorNames } from '../components/color/ColorNames';
import { mapRange } from './ClampNumber';

export interface HSLColor {
  h: number; // Hue value in degrees (0-360)
  s: number; // Saturation value (0-1)
  l: number; // Lightness value (0-1)
}
export interface HSVColor {
  h: number; // Hue value in degrees (0-360)
  s: number; // Saturation value (0-100)
  v: number; // Value (brightness) value (0-100)
}
export interface rgbColor {
  r: number;
  g: number;
  b: number;
  a?: number;
}

//export const isValidHex = (hex: string) => /^#([0-9a-f]{3}){1,2}$/i.test(hex);
export const isValidHex = (hex: string) => /^#([0-9a-f]{3}){1,2}$/i.test(hex) || /^#([0-9a-f]{4}){1,2}$/i.test(hex);

const getChunksFromString = (st: string, chunkSize: number) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a: number, alpha?: number) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const hexToRGB = (hex: string) => {
  if (!isValidHex(hex)) {
    console.log(hex);
    return 'rgb(1,1,1)';
  }
  // Remove the # character if present
  hex = hex.replace('#', '');

  // Convert the hex value to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgb(${r},${g},${b})`;
};

export const hexToRGBAObj = (hex: string) => {
  if (!isValidHex(hex)) {
    console.log(hex);
    throw new Error('Invalid HEX');
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  if (hexArr) {
    const [r, g, b] = hexArr.map(convertHexUnitTo256);
    if (hex.length === 9) {
      const a = parseFloat(((parseInt(hexArr[3], 16) / 255) * 10).toFixed(2));
      return { r, g, b, a };
    } else {
      return { r, g, b };
    }
  } else {
    return { r: 1, g: 1, b: 1, a: 1 };
  }
};

export const hexToRGBAArray = (hex: string, alpha?: number) => {
  if (!isValidHex(hex)) {
    console.log(hex);
    throw new Error('Invalid HEX');
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  if (hexArr) {
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
    return [r, g, b, getAlphafloat(a, alpha)];
  } else {
    return [1, 1, 1, 1];
  }
};

export const rgbToHex = (aColor: string) => {
  if (!aColor.includes('rgb')) return '#FFFFFF';

  const rgbValues = aColor.substring(4, aColor.length - 1);
  const [red, green, blue] = rgbValues.split(',').map((value) => parseInt(value.trim()));

  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  const hexColor = `#${redHex}${greenHex}${blueHex}`;

  return hexColor.toLocaleUpperCase();
};

export const rgbaToHex = (aColor: string, aAlpha = false) => {
  if (!aColor.includes('rgba')) return '#FFFFFF';
  const rgbValues = aColor.substring(5, aColor.length - 1);
  const [red, green, blue, alpha] = rgbValues.split(',').map((value) => parseFloat(value.trim()));

  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  let hexColor = `#${redHex}${greenHex}${blueHex}`;
  if (aAlpha) {
    const value0_255 = Math.round((alpha / 10) * 255);
    const valueHex = value0_255.toString(16).padStart(2, '0');
    hexColor = `${hexColor}${valueHex}`;
  }

  return hexColor.toLocaleUpperCase();
};

//
export const hexToHSL = (hex: string): HSLColor => {
  if (hex.length > 7) hex = hex.substring(0, 7);
  // Check if the hex code is valid
  if (!isValidHex(hex)) {
    console.log(hex);
    console.error('Invalid hex code provided.');
    return { h: 0, s: 0, l: 0 };
  }
  // Remove # symbol if present
  hex = hex.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum RGB components
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the hue
  let h = 0;
  if (max !== min) {
    if (max === r) {
      h = (g - b) / (max - min);
    } else if (max === g) {
      h = 2 + (b - r) / (max - min);
    } else {
      h = 4 + (r - g) / (max - min);
    }
    h *= 60; // Convert to degrees
    if (h < 0) {
      h += 360;
    }
  }

  // Calculate the lightness
  const l = ((max + min) / 2) * 100;

  // Calculate the saturation
  let s = 0;
  if (max !== min) {
    const delta = max - min;
    const sum = max + min;
    s = delta / (sum > 1 ? 2 - sum : sum);
    s *= 100;
  }

  const hslColor: HSLColor = { h, s, l };
  return hslColor;
};

export const HSLToHex = (hsl: string): string => {
  if (hsl.length <= 0) return '';
  // Extract HSL values
  const regex = /hsl\(\s*([\d.]+)\s*,\s*([\d.]+%)\s*,\s*([\d.]+%)\s*\)/;
  const matches = hsl.match(regex);
  if (!matches) {
    console.error('Invalid HSL color format.');
    return '';
  }

  const hue = parseFloat(matches[1]);
  const saturation = parseFloat(matches[2]);
  const lightness = parseFloat(matches[3]);

  // Convert HSL to RGB
  const h = hue / 360;
  const s = saturation / 100;
  const l = lightness / 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h * 6) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 1 / 6) {
    [r, g, b] = [c, x, 0];
  } else if (1 / 6 <= h && h < 2 / 6) {
    [r, g, b] = [x, c, 0];
  } else if (2 / 6 <= h && h < 3 / 6) {
    [r, g, b] = [0, c, x];
  } else if (3 / 6 <= h && h < 4 / 6) {
    [r, g, b] = [0, x, c];
  } else if (4 / 6 <= h && h < 5 / 6) {
    [r, g, b] = [x, 0, c];
  } else if (5 / 6 <= h && h <= 1) {
    [r, g, b] = [c, 0, x];
  }

  // Adjust RGB values
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert RGB to hex
  const rgbHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return `#${rgbHex}`;
};

export const HSVToHex = (hsv: HSVColor): string => {
  const h = hsv.h;
  const s = hsv.s / 100;
  const v = hsv.v / 100;

  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  let r = 0,
    g = 0,
    b = 0;

  if (h >= 0 && h < 60) {
    [r, g, b] = [c, x, 0];
  } else if (h >= 60 && h < 120) {
    [r, g, b] = [x, c, 0];
  } else if (h >= 120 && h < 180) {
    [r, g, b] = [0, c, x];
  } else if (h >= 180 && h < 240) {
    [r, g, b] = [0, x, c];
  } else if (h >= 240 && h < 300) {
    [r, g, b] = [x, 0, c];
  } else if (h >= 300 && h < 360) {
    [r, g, b] = [c, 0, x];
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const rgbHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
  return `#${rgbHex}`;
};

export const parseHSVString = (hsvString: string): HSVColor => {
  const regex = /^hsv\((\d+),\s*(\d+)%,\s*(\d+)%\)$/i;
  const match = hsvString.match(regex);

  if (!match) {
    console.error('Invalid HSV string provided.');
    return { h: 0, s: 0, v: 0 };
  }

  const h = parseInt(match[1], 10);
  const s = parseInt(match[2], 10);
  const v = parseInt(match[3], 10);

  const hsvColor: HSVColor = { h, s, v };
  return hsvColor;
};

//parse hsl string to hsl object
export const parseHSLString = (hslString: string): HSLColor => {
  const regex = /^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/i;
  const match = hslString.match(regex);

  if (!match) {
    console.error('Invalid HSL string provided.');
    return { h: 0, s: 0, l: 0 };
  }

  const h = parseInt(match[1], 10);
  const s = parseInt(match[2], 10);
  const l = parseInt(match[3], 10);

  const hslColor: HSLColor = { h, s, l };
  return hslColor;
};

export const hsvToRGB = (hue: number, saturation: number, value: number) => {
  const chroma = value * saturation;
  const hue1 = hue / 60;
  const x = chroma * (1 - Math.abs((hue1 % 2) - 1));
  let r1 = 1;
  let g1 = 1;
  let b1 = 1;
  if (hue1 >= 0 && hue1 <= 1) {
    [r1, g1, b1] = [chroma, x, 0];
  } else if (hue1 >= 1 && hue1 <= 2) {
    [r1, g1, b1] = [x, chroma, 0];
  } else if (hue1 >= 2 && hue1 <= 3) {
    [r1, g1, b1] = [0, chroma, x];
  } else if (hue1 >= 3 && hue1 <= 4) {
    [r1, g1, b1] = [0, x, chroma];
  } else if (hue1 >= 4 && hue1 <= 5) {
    [r1, g1, b1] = [x, 0, chroma];
  } else if (hue1 >= 5 && hue1 <= 6) {
    [r1, g1, b1] = [chroma, 0, x];
  }

  const m = value - chroma;
  const [r, g, b] = [r1 + m, g1 + m, b1 + m];

  // Change r,g,b values from [0,1] to [0,255]
  return [Math.round(255 * r), Math.round(255 * g), Math.round(255 * b)];
};

export const convertToHex = (color: string): string => {
  if (color.startsWith('#')) {
    if (color.length > 7) {
      return color.substring(0, 7);
    } else {
      return color;
    }
  }
  if (color.startsWith('rgba')) {
    return rgbaToHex(color, true);
  }
  if (color.startsWith('rgb')) return rgbToHex(color);
  if (color.startsWith('hsv')) return HSVToHex(parseHSVString(color));
  if (color.startsWith('hsl')) return HSLToHex(color);
  return color;
};

export const calculateContrast = (aColorOne: string, aColorTwo: string) => {
  // Convert color strings to RGB values
  const rgb1 = hexToRGB(aColorOne);
  const rgb2 = hexToRGB(aColorTwo);

  // Calculate the relative luminance of each color
  const luminance1 = calculateLuminance(rgb1);
  const luminance2 = calculateLuminance(rgb2);

  // Calculate the contrast ratio
  let contrastRatio = (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);

  // Round the contrast ratio to two decimal places
  contrastRatio = Math.round(contrastRatio * 100) / 100;

  return contrastRatio;
};

export const calculateLuminance = (rgb: string) => {
  const rgbValues = rgb.match(/\d+/g)?.map(Number);

  if (rgbValues === undefined) return 0;

  const [red, green, blue] = rgbValues;

  // Convert the RGB values to sRGB
  const sr = red / 255;
  const sg = green / 255;
  const sb = blue / 255;

  // Calculate the relative luminance using the sRGB color space formula
  const r = sr <= 0.03928 ? sr / 12.92 : Math.pow((sr + 0.055) / 1.055, 2.4);
  const g = sg <= 0.03928 ? sg / 12.92 : Math.pow((sg + 0.055) / 1.055, 2.4);
  const b = sb <= 0.03928 ? sb / 12.92 : Math.pow((sb + 0.055) / 1.055, 2.4);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance;
};

export const convertToRGB = (color: string): { r: number; g: number; b: number; a?: number } => {
  if (color.startsWith('#')) {
    return hexToRGBAObj(color);
  }
  if (color.startsWith('rgba')) {
    return parseRGBString(color);
  }
  if (color.startsWith('rgb')) {
    return parseRGBString(color);
  }
  if (color.startsWith('hsl')) {
    return hexToRGBAObj(HSLToHex(color));
  }
  if (color.startsWith('hsv')) {
    return hexToRGBAObj(HSVToHex(parseHSVString(color)));
  }
  return { r: 0, g: 0, b: 0 };
};

export const parseRGBString = (rgbString: string) => {
  const rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i;
  const match = rgbString.match(rgbRegex);

  if (!match) {
    throw new Error('Invalid RGB string');
  }

  const [, r, g, b, a] = match;
  const red = parseInt(r, 10);
  const green = parseInt(g, 10);
  const blue = parseInt(b, 10);

  if (a !== undefined) {
    const alpha = parseFloat(a);
    return { r: red, g: green, b: blue, a: alpha };
  }

  return { r: red, g: green, b: blue };
};

export const getColorName = (aColor: string) => {
  let lowest = Number.POSITIVE_INFINITY;
  let tmp = 0;
  let index = 0;

  for (let i = 0; i < colorNames.length; i++) {
    const element = colorNames[i];

    const colorOne = hexToRGBAObj(aColor);
    const colorTwo = hexToRGBAObj(element.hex);
    tmp = distance(colorOne, colorTwo);
    if (tmp < lowest) {
      lowest = tmp;
      index = i;
    }
  }
  return colorNames[index].name;
};

const distance = (a: rgbColor, b: rgbColor) => {
  return Math.sqrt(Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2));
};

//TODO: To adjust colors to be better contrast, move the luminance value in HSL in the opposite way of color one.
//IE. Color One = HSL(124,61,14). Color Two =  HSL(124,61,14).
//To adjust Color Two to match, move L of Color two towards 100 (max) untill either the contrast is good or the background is white.
//And if the text is light (over 50 luminance) move the background color to darker.
