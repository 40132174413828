/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useStore } from '../../store/store';
import { clamp } from '../../utils/ClampNumber';

interface INewWindowProps {
  source: string;
  isOpen: boolean;
  children?: React.ReactNode;
  setPreviewWindow: (w: Window) => void;
  currentWindowSize: string;
}

export default function NewWindow({ source, isOpen, children, setPreviewWindow: setWindow, currentWindowSize }: INewWindowProps) {
  const { previewStore, courseStore } = useStore();
  const { setPreviewOpen, currentPreviewCourseID, currentPreviewCourseTitle } = previewStore;
  const { courseViewModel, courseProperties } = courseStore;
  const [container, setContainer] = useState<HTMLDivElement | null>();
  const [previewWindow, setPreviewWindow] = useState<Window | null>();
  const [defaultWindowSize] = useState<{ w: number; h: number }>({
    w: courseProperties?.descriptions.items.pageWidth.option.value ? parseInt(courseProperties.descriptions.items.pageWidth.option.value) + 100 : 1500,
    h: courseProperties?.descriptions.items.pageHeight.option.value ? parseInt(courseProperties.descriptions.items.pageHeight.option.value) + 240 : 1100,
  });

  const handleWindowSizeChange = (s: string) => {
    switch (s) {
      case 'Mobile':
        return { w: 550, h: defaultWindowSize.h };
        break;
      case 'Tablet':
        return { w: 870, h: defaultWindowSize.h };
        break;
      default:
        return { w: defaultWindowSize.w, h: defaultWindowSize.h };
        break;
    }
  };

  useEffect(() => {
    if (previewWindow !== null && previewWindow !== undefined) {
      const newSize = handleWindowSizeChange(currentWindowSize);
      previewWindow.resizeTo(newSize.w, newSize.h);
    }
  }, [currentWindowSize]);

  useEffect(() => {
    if (isOpen) {
      setContainer(document.createElement('div'));
    } else {
      setContainer(null);
      handleClosePreview();
    }
  }, [isOpen]);

  useEffect(() => {
    if (previewWindow) {
      previewWindow.focus();
    }
  }, [source]);

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  useEffect(() => {
    let curWindow: Window | null;

    if (container) {
      let externalWindow: Window | null = null;

      let pageHeight = defaultWindowSize.h;
      let pageWidth = defaultWindowSize.w;

      if (currentWindowSize !== 'Desktop') {
        const size = handleWindowSizeChange(currentWindowSize);
        pageHeight = size.h;
        pageWidth = size.w;
      }

      externalWindow = popupCenter('', courseViewModel ? 'Preview - ' + courseViewModel.title : 'Preview', Math.min(pageWidth, screen.width), Math.min(pageHeight, screen.height));

      if (externalWindow) {
        externalWindow.document.body.style.margin = '0';
        externalWindow.document.body.style.backgroundColor = '#303030';
        externalWindow.onbeforeunload = handleClosePreview;
        externalWindow.document.body.appendChild(container);

        const styleElement = externalWindow.document.createElement('style');
        // eslint-disable-next-line quotes
        styleElement.textContent = "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');";
        externalWindow.document.head.appendChild(styleElement);
      }
      curWindow = externalWindow;
      setPreviewWindow(externalWindow);
      if (externalWindow !== null) setWindow(externalWindow);
    }

    return () => curWindow?.close();
  }, [container]);

  useEffect(() => {
    if (previewWindow !== null && previewWindow !== undefined) {
      previewWindow.document.title = currentPreviewCourseTitle ? 'Preview - ' + currentPreviewCourseTitle : 'Preview';
    }
  }, [currentPreviewCourseID]);

  const popupCenter = (url: string, title: string, w: number, h: number) => {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;

    const newWindow = window.open(
      '',
      '',
      `
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `,
    );

    if (newWindow) {
      newWindow.document.title = title;
    }
    return newWindow;
  };

  if (container != null) {
    return ReactDOM.createPortal(children, container);
  } else {
    return <></>;
  }
}
