import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { EOwnerCourseListSortByType, TOwnerCourseViewModel } from '../services/models/OwnerViewModel';
import { TToolBox } from '../services/models/ToolboxModel';
import { ICWResponseObj, IWizard } from '../services/models/WizardViewModel';
import { store } from './store';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';
import { TPropertyValueView } from '../services/models/PropertyViewModel';
import { debounce } from '../utils/Debounce';

export interface courseListType {
  id: number;
  course: TOwnerCourseViewModel;
}

export type ViewMode = 'Grid' | 'List' | 'CompactList';

export enum ECourseViewType {
  all,
  owned,
  sharedToOwner,
  sharedByOwner,
}

export default class OwnerStore {
  courseList: courseListType[] | undefined = undefined;
  selectedCourse: TOwnerCourseViewModel | undefined = undefined;
  selectedCourseID: number = 0;
  sortBy: EOwnerCourseListSortByType = EOwnerCourseListSortByType.Altered;
  searchQuery = '';
  toolBox: TToolBox | undefined = undefined;
  courseWizardOld: IWizard | undefined = undefined;
  courseWizardOpen = false;
  CurrentSideViewTab = 3; //3 == news;
  viewMode: ViewMode = 'Grid';
  editMode = false;
  loadingInitial = true;
  loadingExtra = false;
  uploading = false;
  courseViewType: ECourseViewType = ECourseViewType.all; // Brukt av Owner.getCourses for å hente "grupperte" kurs.

  // Queue for processing requests
  requestQueue: number[] = [];
  isProcessingQueue = false;

  constructor() {
    makeAutoObservable(this);

    const savedViewMode = localStorage.getItem('viewMode');
    if (savedViewMode !== this.viewMode && savedViewMode !== null) {
      this.viewMode = savedViewMode as ViewMode;
    }

    const tabSelectionLS = localStorage.getItem('tabSelection');
    if (tabSelectionLS !== null) {
      try {
        const tabSelection = parseInt(tabSelectionLS);
        if (tabSelection !== this.CurrentSideViewTab) {
          this.CurrentSideViewTab = tabSelection;
        }
      } catch {
        this.CurrentSideViewTab = 3;
      }
    }
  }

  loadOwnerViewModel = async () => {
    try {
      const courses = await agent.Owner.getCourses(this.sortBy, this.searchQuery, this.courseViewType);
      //const test = await agent.User.getUser();
      //console.log(test);
      try {
        this.setOwnerViewModel(courses);
      } catch (error) {
        console.log(error);
      }
      this.setLoadingInitial(false);
      this.setLoadingExtra(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      this.setLoadingExtra(false);
    }
  };

  setCourseViewType = (aCourseViewType: ECourseViewType) => {
    this.courseViewType = aCourseViewType;
    this.loadOwnerViewModel();
  };

  selectCourse = (aCourse: TOwnerCourseViewModel) => {
    this.selectedCourse = aCourse;
    this.selectedCourseID = aCourse.courseID;
  };

  createCourse = async (aResponseObj: ICWResponseObj) => {
    try {
      const newCourse: TOwnerCourseViewModel[] = await agent.Owner.createCourse(JSON.stringify(aResponseObj));
      this.addNewCourse(newCourse[0]);
      return newCourse[0];
      //this.closeWizard();
    } catch (error) {
      console.log(error);
    }
  };

  copyCourse = async (courseID: number) => {
    try {
      //Gjøre noe med teksten som sier at dette kan ta tid. Bare bruk systemet på vanlig måte
      SnackbarUtils.copyingStart('Copying in progress.');
      const duplicatedCourse: TOwnerCourseViewModel[] = await agent.Owner.copyCourse(courseID);
      if (duplicatedCourse) {
        this.addNewCourse(duplicatedCourse[0]);
        SnackbarUtils.copyingClose(duplicatedCourse[0].courseID);
      }
    } catch (error) {
      console.log(error);
    }
  };

  addNewCourse = (newCourse: TOwnerCourseViewModel) => {
    if (this.courseList === undefined) return;
    this.courseList.unshift({ id: newCourse.courseID, course: newCourse });

    store.courseStore.getCourseProperties(newCourse.courseID);
    //store.courseStore.OpenCourseProperties();
    //this.setSideViewTab(0);
  };

  private addCourseToList = (course: TOwnerCourseViewModel) => {
    if (this.courseList === undefined) return;
    this.courseList.push({ id: course.courseID, course: course });
  };

  private setOwnerViewModel(courses: TOwnerCourseViewModel[]) {
    this.courseList = [];
    courses.forEach((course) => {
      this.addCourseToList(course);
    });
  }

  courseListSortBy = (sortBy: any) => {
    this.sortBy = sortBy as EOwnerCourseListSortByType;
    this.loadOwnerViewModel();
  };

  courseListSearchQuery = (aQuery: string) => {
    this.searchQuery = aQuery;
    this.loadOwnerViewModel();
  };

  getToolBox = async () => {
    //const tBox = await agent.Owner.getToolBox();
    try {
      runInAction(() => {
        //this.toolBox = tBox;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateCourseOption = debounce(
    async (selcCourseId: number, aPropertyValueView: TPropertyValueView, value: string) => {
      try {
        await store.propertyStore.setOption(aPropertyValueView, value);
        if (selcCourseId !== 0) {
          this.updateCourseView(selcCourseId);
          store.courseStore.updateCourseProperties(selcCourseId);
        }
      } catch (error) {
        console.log(error);
      }
    },
    1000, // 1 second debounce delay
  );

  updateCourseView = async (courseId: number) => {
    if (this.courseList === undefined) return;
    const newCourse: TOwnerCourseViewModel[] = await agent.Owner.getCourse(courseId);
    const course = this.courseList.find((x) => x.course.courseID == courseId);
    if (course) {
      runInAction(() => {
        course.course = newCourse[0];
        this.selectedCourse = newCourse[0];
        this.selectedCourseID = newCourse[0].courseID;
      });
    } else {
      console.log('CourseId ' + courseId + ' is not in courselist');
    }
  };

  deleteCourse = async (courseID: number) => {
    if (this.courseList === undefined) return;
    const response = await agent.Owner.deleteCourse(courseID);
    if (response != 500) {
      /* SnackbarUtils.success('Course deleted', () => this.setDarkMode(!this.darkMode), 'undo', 5000); */
      SnackbarUtils.success('Course deleted', undefined, undefined, 5000);
      store.courseStore.deselectCourseProperties();
    }
    this.courseList = this.courseList.filter((element) => {
      return element.id != courseID;
    });
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoadingExtra = (state: boolean) => {
    this.loadingExtra = state;
  };

  closeForm = () => {
    this.editMode = false;
  };

  toggleViewMode = (aViewMode: ViewMode) => {
    this.viewMode = aViewMode;
    localStorage.setItem('viewMode', aViewMode);
  };

  setSideViewTab = (n: number) => {
    localStorage.setItem('tabSelection', n.toString());
    console.log('n', n);
    if (n != undefined) this.CurrentSideViewTab = n;
  };

  findOwner = async (aEmail: string) => {
    return await agent.Owner.findOwner(aEmail);
  };

  shareCourse = async (aCourseID: number, aUserID: string) => {
    return await agent.Owner.setShareCourses([aUserID], [aCourseID], 1).then(() => {
      this.updateSharedUsers(aCourseID);
    });
  };

  removeUserFromCourse = (aCourseID: number, aUserID: string) => {
    agent.Owner.setShareCourses([aUserID], [aCourseID], 0).then(() => {
      this.updateSharedUsers(aCourseID);
    });
  };

  updateSharedUsers = (aCourseID: number) => {
    agent.Owner.getSharedUsers(aCourseID).then((sharedUsers) => {
      runInAction(() => {
        const course = this.courseList?.find((x) => x.id === aCourseID);
        if (course) {
          course.course.sharedUsers = sharedUsers;
        }
      });
    });
  };

  removeSharedCourse = (courseID: number, userID: string) => {
    agent.Owner.setShareCourses([userID], [courseID], 0).then(() => {
      if (this.courseList) {
        this.courseList = this.courseList?.filter((x) => x.id !== courseID);
      }
    });
  };

  // Public method to add a course ID to the queue
  enqueueRequest = (courseID: number) => {
    if (!this.requestQueue.includes(courseID)) {
      this.requestQueue.push(courseID);
      this.processQueue();
    }
  };

  // Processes the queue one item at a time
  private processQueue = async () => {
    if (this.isProcessingQueue || this.requestQueue.length === 0) return;
    this.isProcessingQueue = true;

    while (this.requestQueue.length > 0) {
      const courseID = this.requestQueue.shift();
      if (courseID) {
        try {
          await agent.Owner.getSharedUsers(courseID)
            .then((sharedUsers) => {
              runInAction(() => {
                // Update the relevant course with shared users
                const course = this.courseList?.find((x) => x.id === courseID);
                if (course) {
                  course.course.sharedUsers = sharedUsers;
                }
              });
            })
            .catch(() => {
              console.log('Error');
            });
        } catch (error) {
          console.error(`Failed to fetch shared users for course ${courseID}`, error);
        }
      }
    }

    this.isProcessingQueue = false;
  };
}
