import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../services/api/agent';
import { IContent } from '../../services/models/PageViewModel';
import { store } from '../store';

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
}
export default class ImageEditorStore {
  mediaLibraryFolderPath = '';
  editorSource = '';
  imageFileName = ''; //Brukes for å vise path og filnavn til bruker.
  mediaTitle = ''; //Brukes for å passe på at fil har et navn når den lagres på nytt.
  contentSource: IContent | undefined = undefined;
  isImageEditorOpen = false;
  isVideoEditorOpen = false;
  isImageShared = false;
  editedImageUrl = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClose: (s?: string) => void = (s?: string) => {
    return;
  };

  constructor() {
    makeAutoObservable(this);
  }

  saveImage = async (file: File, type: MediaType = MediaType.Image) => {
    let path = this.mediaLibraryFolderPath;
    if (this.mediaLibraryFolderPath.length <= 0) {
      path = `${MediaType[type]} Editor`;
    }
    if (this.isImageShared && !path.includes('shared\\')) {
      path = `shared\\${path}`;
    }
    const formData = new FormData();
    formData.append('formFiles', file);
    const response = await agent.Media.uploadFile(path, formData);
    const keys = Object.keys(response);
    this.editedImageUrl = response[keys[0]].url;
    if (this.contentSource !== undefined) {
      store.pageStore.UpdateContentInnerHTMLFromID(this.contentSource.contentID, this.editedImageUrl);
    }
    store.mediaLibraryStore.getFilesInFolder(store.mediaLibraryStore.currentFolderPath);
    return;
  };

  openImageEditor = async (source: string, mediaLibraryPath?: string, content?: IContent, fileName?: string, onClose?: () => void, type: MediaType = MediaType.Image) => {
    if (content !== undefined) {
      this.contentSource = content;
    }
    this.mediaLibraryFolderPath = mediaLibraryPath ? mediaLibraryPath : '';
    this.imageFileName = fileName !== undefined ? fileName : '';
    if (!mediaLibraryPath) {
      const response = await agent.Media.getLocalPathFromURL(source);
      if (response.status) {
        runInAction(() => {
          this.mediaLibraryFolderPath = response.virtualFolder;
          this.imageFileName = response.virtualFolder.replaceAll('\\', '/') + '/' + response.mediaTitle;
          this.mediaTitle = response.mediaTitle;
          this.isImageShared = response.isShared;
        });
      }
    }

    if (source.length > 0) {
      if (type === MediaType.Image) {
        this.editorSource = source;
        this.isImageEditorOpen = true;
      } else if (type === MediaType.Video) {
        this.editorSource = source;
        this.isVideoEditorOpen = true;
      }
    }

    if (onClose)
      runInAction(() => {
        this.onClose = onClose;
      });
  };

  closeImageEditor = () => {
    this.isImageEditorOpen = false;
    this.isVideoEditorOpen = false;
    this.editorSource = '';
    this.mediaLibraryFolderPath = '';
    this.contentSource = undefined;
    this.onClose(this.editedImageUrl);
    this.editedImageUrl = '';
    this.isImageShared = false;
  };

  closeVideoEditor = () => {
    this.isVideoEditorOpen = false;
    /* this.editorSource = ''; */
    this.mediaLibraryFolderPath = '';
    this.contentSource = undefined;
    this.editedImageUrl = '';
  };
}
