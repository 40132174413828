import { useState } from 'react';
import { cleanFileName } from '../../utils/cleanFileName';
import agent from '../../services/api/agent';
import { escapeTextForSSML } from '../../utils/EscapeTextForSSML';

// Define types for the hook return values
interface UseTTSReturn {
  fetchTTS: (text: string, title: string, id: string, language: string, voice: string, format: string) => Promise<void>;
  audioUrl: string;
  audioDuration: number | null;
  loading: boolean;
  error: string | null;
  audioFile: File | null;
}

const useTTS = (): UseTTSReturn => {
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [audioDuration, setAudioDuration] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [audioFile, setAudioFile] = useState<File | null>(null);

  const fetchTTS = async (text: string, title: string, id: string, language: string, voice: string, format: string) => {
    setLoading(true);
    setError(null);

    try {
      const cleanText = escapeTextForSSML(text);
      const audioData = await agent.Proxy.AzureTTS(cleanText, language, voice, format);

      const fileName = `${cleanFileName(title)}_voice_${language}_${id}.mp3`;
      const file = new File([audioData], fileName, { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioData);
      const audioElement = new Audio(audioUrl);

      const handleLoadedMetadata = () => {
        setAudioDuration(audioElement.duration); // Get the duration in seconds
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };

      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      setAudioUrl(audioUrl);
      setAudioFile(file);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { fetchTTS, audioUrl, audioDuration, loading, error, audioFile };
};

export default useTTS;
