import React, { useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button';
import { useStore } from '../store/store';

interface UseRemoveCourseDialogReturn {
  openDialog: (courseID: number, onComplete?: () => void) => void;
  RenderDialog: React.FC;
}

export function useRemoveCourseDialog(): UseRemoveCourseDialogReturn {
  const { userStore, ownerStore } = useStore();
  const { removeSharedCourse } = ownerStore;
  const { user } = userStore;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseID, setCourseID] = useState<number | null>(null);
  const [onCompleteCallback, setOnCompleteCallback] = useState<(() => void) | null>(null);

  const openDialog = useCallback((id: number, onComplete?: () => void) => {
    setCourseID(id);
    setOnCompleteCallback(() => onComplete);
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setCourseID(null);
  }, []);

  const handleRemoveSharedCourse = useCallback(() => {
    if (user?.sub && courseID !== null) {
      removeSharedCourse(courseID, user.sub);
    }
    closeDialog();
    if (onCompleteCallback) {
      onCompleteCallback();
    }
  }, [user?.sub, courseID, removeSharedCourse, closeDialog]);

  const RenderDialog: React.FC = () => (
    <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby='alert-dialog-remove-course' aria-describedby='alert-dialog-remove-course-description'>
      <DialogTitle id='alert-dialog-remove-course'>Remove access to the shared course?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-remove-course-description'>
          This will remove the shared course from your account. To regain access, the owner needs to share the course with you again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='newSecondary' onClick={handleRemoveSharedCourse} autoFocus>
          Remove
        </Button>
        <Button variant='newSecondary' onClick={closeDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { openDialog, RenderDialog };
}
