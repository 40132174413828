import React from 'react';
import { useStore } from '../../../store/store';
import DashboardPageProperties from './DashboardPageProperties';
import DrawerProperties from '../../courseOverview/drawer/DrawerProperties';
import { observer } from 'mobx-react';

export default observer(function DashboardPagePropertiesDrawer() {
  const { courseStore } = useStore();
  const { pageProperties, courseProperties } = courseStore;

  return (
    <>
      {pageProperties === undefined ? (
        <>{courseProperties && <DrawerProperties />}</>
      ) : (
        <>
          <DashboardPageProperties />
        </>
      )}
    </>
  );
});
