import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';

interface IWrapperProps {
  wrapper: boolean;
  isWrapperOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  parentElement: HTMLElement; // Parent element for positioning reference
}

const Wrapper = ({ wrapper, isWrapperOpen, onClose, children, parentElement }: IWrapperProps) => {
  const useStyles = createUseStyles({
    overlayContainer: {
      position: 'absolute',
      zIndex: 1000,
      backgroundColor: '#212121',
      border: '0px',
      borderRight: '2px solid #3c3c3c',
      padding: '24px',
      borderRadius: '0px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      display: 'block',
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 999,
      display: 'block',
    },
  });

  const classes = useStyles();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [positionCalculated, setPositionCalculated] = useState(false);

  useEffect(() => {
    if (parentElement && isWrapperOpen) {
      const rect = parentElement.getBoundingClientRect();
      const wrapperHeight = 250;
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - rect.bottom;

      let newTop = rect.bottom + window.scrollY + 20;

      if (spaceBelow < wrapperHeight + 20) {
        newTop = rect.top + window.scrollY - wrapperHeight - 20;
      }

      setPosition({
        top: newTop - 42,
        left: rect.left + window.scrollX - 318,
      });
      setPositionCalculated(true);
    }
  }, [parentElement, isWrapperOpen]);

  const handleClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (wrapper && isWrapperOpen && positionCalculated) {
    return createPortal(
      <>
        <div className={classes.backdrop} onClick={handleClose} />
        <div className={classes.overlayContainer} style={{ top: position.top, left: position.left }}>
          {children}
        </div>
      </>,
      document.body, // Render at the root level
    );
  }

  return null;
};

export default Wrapper;
