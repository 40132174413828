import { createUseStyles } from 'react-jss';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Resize } from '../../icons/Icons';
import { observer } from 'mobx-react';
import { debounce } from '../../../utils/Debounce';
import Tooltip from '@mui/material/Tooltip/Tooltip';

interface IPercentageInputProps {
  defaultValue: number;
  handleChange: (n: number) => void;
}

export default observer(function PercentageInput({ defaultValue, handleChange }: IPercentageInputProps) {
  const [, setValue] = useState<number>(defaultValue);
  //const [value, setValue] = useState<number>(defaultValue);
  const [isPointerLocked, setIsPointerLocked] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const cursorRef = useRef<HTMLDivElement>(null);

  const sensitivity = 0.2;

  const handleMouseUp = () => {
    setIsPointerLocked(false);
    document.exitPointerLock();
  };

  // Update cursor position with wrap-around logic
  const handleMouseMove = (event: MouseEvent) => {
    setValue((prevValue) => {
      const newValue = Math.max(0, Math.min(100, prevValue + event.movementX * sensitivity));
      handleValueChange(Math.round(newValue));
      return newValue;
    });

    setCursorPosition((prevPosition) => {
      let newX = prevPosition.x + event.movementX;
      let newY = prevPosition.y + event.movementY;
      // Wrap-around logic: if the cursor goes out of bounds, it appears on the other side
      if (newX < 0) newX = window.innerWidth;
      if (newX > window.innerWidth) newX = 0;
      if (newY < 0) newY = window.innerHeight;
      if (newY > window.innerHeight) newY = 0;

      return { x: newX, y: newY };
    });
  };

  // Start pointer lock on button click
  const startPointerLock = (e: React.MouseEvent) => {
    setIsPointerLocked(true);
    document.body.requestPointerLock();
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  const handleInput = (e: any) => {
    const value = parseInt(e.target.value.replace(/\D/g, '')) || 0;
    handleValueChange(value);
  };

  const handleValueChange = (aValue: number) => {
    const min = 0,
      max = 100;

    if (aValue > max) {
      aValue = max;
    } else if (aValue < min) {
      aValue = min;
    }
    //setValue(aValue);
    commitValue(aValue);
  };

  const commitValue = useCallback(
    debounce((finalValue: number) => {
      handleChange(finalValue);
    }, 1),
    [handleChange],
  );

  const useStyles = createUseStyles({
    input: {
      width: 25,
      textAlign: 'right',
      border: 0,
      backgroundColor: 'unset',
      color: 'white',
      fontSize: 15,
      '&:focus-visible': {
        outline: '1px solid #8f8f8f',
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (isPointerLocked) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isPointerLocked]);

  // Custom cursor component, rendered with createPortal
  const cursorElement = (
    <div
      ref={cursorRef}
      style={{
        display: !isPointerLocked ? 'none' : 'block',
        position: 'absolute',
        top: cursorPosition.y + 2,
        left: cursorPosition.x,
        width: '24px',
        height: '24px',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        zIndex: 999999,
      }}
    >
      <div style={{ color: 'white' }}>
        <Resize size={25} />
      </div>
    </div>
  );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={'Opacity'} placement='bottom' arrow>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <input type='text' value={defaultValue} onChange={handleInput} className={classes.input} />
            <div
              style={{
                cursor: 'ew-resize',
                userSelect: 'none',
                marginRight: 3,
              }}
              onMouseDown={startPointerLock}
            >
              %
            </div>
          </div>
        </Tooltip>
      </div>
      {createPortal(cursorElement, document.body)}
    </>
  );
});
