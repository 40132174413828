/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import Accordion from '../../components/accordion/Accordion';
import { Theme, useTheme } from '@mui/material/styles';
import { IPagePlaceholder, TPageViewModel } from '../../services/models/PageViewModel';
import { runInAction } from 'mobx';
import { useStore } from '../../store/store';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import { IPropertyGroupColor, IPropertyValue } from '../../services/models/PropertyViewModel';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { BiggerTooltip } from '../../components/tooltip/BiggerTooltip';
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import ToggleButton from '@mui/material/ToggleButton/ToggleButton';
import Modal from '../../components/modal/Modal';
import { GradientList } from '../../utils/style/GradientList';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import Divider from '@mui/material/Divider/Divider';

interface IBackgroundGradientPropertyProps {
  propertyGroup: IPropertyGroupColor;
  placeholder?: IPagePlaceholder;
  page?: TPageViewModel;
}
interface test {
  x: number;
  y: number;
}
export default observer(function BackgroundGradientProperty({ propertyGroup, placeholder, page }: IBackgroundGradientPropertyProps) {
  const theme = useTheme();
  const [defaultOpen, setDefaultOpen] = useState(false);
  const { propertyStore } = useStore();
  const { setDesignProperty, setDesignPropertyList } = propertyStore;
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [gradientStyle, setGradientStyle] = useState('linear');
  const [bgColors, setBgColors] = useState(['', '']);
  const [degree, setDegree] = useState('90');

  const divRef = useRef<HTMLDivElement>(null);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [centerPos, setCenterPos] = useState<test>({ x: 0, y: 0 });

  let timeout: NodeJS.Timeout | undefined = undefined;

  const handleColorChange = () => {
    if (timeout == undefined) {
      timeout = setTimeout(() => {
        updateColor();
      }, 100);
    }
  };

  const updateColor = () => {
    if (bgColors[0] === '' || bgColors[1] == '') return;
    runInAction(() => {
      const gradientString = `${gradientStyle}-gradient(${gradientStyle === 'linear' ? degree + 'deg' : 'circle'}, ${bgColors[0]}, ${bgColors[1]})`;

      const propertyList: IPropertyValue[] = [
        { propertyValueView: propertyGroup.items.backgroundColor, value: bgColors[0] },
        { propertyValueView: propertyGroup.items.backgroundImage, value: gradientString },
      ];

      if (propertyList[0].value !== propertyGroup.items.backgroundColor.option.value || propertyList[1].value != propertyGroup.items.backgroundImage.option.value)
        setDesignPropertyList(propertyGroup, propertyList);

      propertyGroup.items.backgroundColor.option.value = bgColors[0];
      propertyGroup.items.backgroundImage.option.value = gradientString;

      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = bgColors[0];
        placeholder.colorAndDesign.backgroundImage = gradientString;
      }
      if (page !== undefined) {
        page.backgroundColor = bgColors[0];
        page.backgroundImage = gradientString;
      }
      timeout = undefined;
    });
  };

  const handleRemoveBackgroundGradient = () => {
    setBgColors(['', '']);
    setDesignPropertyList(propertyGroup, [
      { propertyValueView: propertyGroup.items.backgroundColor, value: '' },
      { propertyValueView: propertyGroup.items.backgroundImage, value: '' },
    ]);

    runInAction(() => {
      propertyGroup.items.backgroundColor.option.value = '';
      propertyGroup.items.backgroundImage.option.value = '';
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = '';
        placeholder.colorAndDesign.backgroundImage = '';
      }
      if (page !== undefined) {
        page.backgroundColor = '';
        page.backgroundImage = '';
      }
    });
  };

  const handleGradientStyleChange = (event: React.MouseEvent<HTMLElement>, aValue: string) => {
    if (aValue !== null) {
      setGradientStyle(aValue);
      setEditMode(false);
    }
  };

  //linear-gradient(90deg, #084b83, #42bfdd)
  const handleAddBackgroundGradient = () => {
    handleRemoveBackgroundImage();
    setDefaultOpen(true);
    const randomGradient = GradientList[Math.floor(Math.random() * GradientList.length)];

    setBgColors([randomGradient[0], randomGradient[1]]);
    setDegree('90');
  };

  const handleSelectGradient = (gradient: string[]) => {
    setBgColors([gradient[0], gradient[1]]);
    setDegree('90');
    setIsLibraryOpen(false);
  };

  const onMouseDown = () => {
    setIsMouseDown(true);
  };
  const onMouseUp = () => {
    if (!isMouseDown) return;
    setIsMouseDown(false);
    updateColor();
  };

  const onMouseMove = (e: any) => {
    if (divRef.current == null) return;
    if (!isMouseDown) return;
    setDegree(angle(e.clientY, e.clientX));
  };

  function getCenter(rect: DOMRect) {
    const { left, top, width, height } = rect;
    return { x: left + width / 2, y: top + height / 2 };
  }

  const angle = (clientY: number, clientX: number) => {
    let deg = Math.atan2(clientY - centerPos.y, clientX - centerPos.x) * (180 / Math.PI);
    deg = deg < 0 ? 360 + deg : deg;
    return deg.toFixed();
  };

  const handleRemoveBackgroundImage = () => {
    runInAction(() => {
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundImage = '';
        placeholder.colorAndDesign.backgroundPosition = '';
        placeholder.colorAndDesign.backgroundSize = '';
        placeholder.colorAndDesign.backgroundRepeat = 't';
      }
      if (page !== undefined) {
        page.backgroundImage = '';
        page.backgroundPosition = '';
        page.backgroundSize = '';
        page.backgroundRepeat = '';
      }
    });
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    colorPicker: {
      cursor: 'pointer',
      WebkitAppearance: 'none',
      border: 'none',
      width: 64,
      height: 36,
      padding: 0,
      '&::-webkit-color-swatch-wrapper': {
        padding: 0,
      },
      '&::-webkit-color-swatch': {
        border: 'none',
      },
    },
    degreeNotch: {
      position: 'absolute',
      top: 5,
      left: 90,
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #fff',
      zIndex: 9,
    },
    degreeStepper: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '100%',
      outline: '2px dotted white',
      outlineOffset: -25,
      pointerEvents: 'none',
    },
    degreeButton: {
      cursor: 'pointer',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      '&:hover': {
        '& $degreeIcon': {
          backgroundColor: 'white',
          '&:after': {
            backgroundColor: theme.palette.bgTwo.main,
          },
        },
      },
    },
    degreeIcon: {
      outline: '2px solid white',
      transition: 'all 200ms',
      borderRadius: '100%',
      width: 18,
      height: 18,
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 8,
        width: 2,
        height: 9,
        backgroundColor: 'white',
        transition: 'background-color 200ms',
        zIndex: 9,
      },
    },
  }));
  const classes = useStyles({ theme });

  const regEx = new RegExp(/\(([^)]+)\)/g);

  useEffect(() => {
    if (divRef.current == null) return;
    const rect = divRef.current.getBoundingClientRect();
    setCenterPos({ x: getCenter(rect).x, y: getCenter(rect).y });
  }, [editMode]);

  useEffect(() => {
    if (propertyGroup.items.backgroundColor.option.value === '' || propertyGroup.items.backgroundImage.option.value === '') {
      return;
    }

    if (propertyGroup.items.backgroundImage.option.value.length < 1) {
      return;
    }

    const gradient = regEx.exec(propertyGroup.items.backgroundImage.option.value);
    if (gradient != null) {
      const gradientItems = gradient[1].split(',');
      let colors = [];
      for (let i = 1; i < gradientItems.length; i++) {
        const element = gradientItems[i];
        colors.push(element.trim());
      }

      if (gradientItems[0].includes('circle')) {
        setGradientStyle('radial');
      } else {
        setDegree(gradientItems[0].replace('deg', ''));
      }

      if (bgColors[0] !== colors[0] || bgColors[1] !== colors[1]) setBgColors([colors[0], colors[1]]);
    }
  }, []);

  useEffect(() => {
    if (bgColors[0] === '' || bgColors[1] === '') {
      return;
    }
    handleColorChange();
  }, [bgColors, gradientStyle]);

  if (propertyGroup.items.backgroundColor.option.value == '' || propertyGroup.items.backgroundImage.option.value == '') {
    return (
      <>
        <Divider />
        <AddStyle styleName={'Background Gradient'} addStyle={handleAddBackgroundGradient} />
      </>
    );
  } else {
    return (
      <>
        <div onMouseUp={onMouseUp}>
          <Accordion title={'Background Gradient'} isOpen={defaultOpen}>
            <BiggerTooltip title={'Remove background gradient'}>
              <IconButton onClick={handleRemoveBackgroundGradient} style={{ position: 'absolute', top: 0, right: 15 }}>
                <DeleteIcon />
              </IconButton>
            </BiggerTooltip>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: theme.spacing(2), paddingLeft: 0, paddingRight: 0, paddingTop: theme.spacing(5) }}>
              <div
                style={{
                  width: '100%',
                  maxWidth: 250,
                  aspectRatio: 1,
                  background: `${gradientStyle}-gradient(${gradientStyle == 'linear' ? degree + 'deg' : 'circle'}, ${bgColors[0]}, ${bgColors[1]})`,
                  borderRadius: '10%',
                  position: 'relative',
                }}
              >
                {editMode && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#00000059',
                      borderRadius: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div ref={divRef} style={{ width: '80%', height: '80%', borderRadius: '100%', position: 'relative', transform: 'rotate(90deg)' }} onMouseMove={onMouseMove}>
                      <div style={{ width: '100%', height: '100%', transform: `rotate(${degree}deg)` }}>
                        <div className={classes.degreeNotch} style={{ cursor: 'pointer', zIndex: 9999 }} onMouseDown={onMouseDown}></div>
                      </div>
                      <div className={classes.degreeStepper}></div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        width: 40,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999,
                      }}
                    >
                      {degree}°
                    </div>
                    <IconButton onClick={() => setEditMode(false)} size='large' sx={{ position: 'absolute', top: 5, right: 5 }}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                  <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' exclusive fullWidth value={gradientStyle} onChange={handleGradientStyleChange}>
                    <ToggleButton value='linear' key='linear'>
                      Linear
                    </ToggleButton>
                    <ToggleButton value='radial' key='radial'>
                      Radial
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {gradientStyle === 'linear' ? (
                    <ToggleButton color='primary' value='focus' size='small' fullWidth selected={editMode} onClick={() => setEditMode(!editMode)}>
                      {degree}°
                    </ToggleButton>
                  ) : (
                    <div style={{ height: 38.75 }}></div>
                  )}

                  <ToggleButton color='primary' value='focus' size='small' selected={isLibraryOpen} onClick={() => setIsLibraryOpen(true)}>
                    Open Library
                  </ToggleButton>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                  <input className={classes.colorPicker} value={bgColors[0]} type='color' onChange={(e) => setBgColors([e.target.value, bgColors[1]])} onTouchMove={(e: any) => e.preventDefault()} />
                  <input className={classes.colorPicker} value={bgColors[1]} type='color' onChange={(e) => setBgColors([bgColors[0], e.target.value])} onTouchMove={(e: any) => e.preventDefault()} />

                  {/*                 <Button variant='outlined' color='gray'>
                  +
                </Button> */}
                </div>
              </div>
            </div>
          </Accordion>
          <Modal isOpen={isLibraryOpen} onClose={() => setIsLibraryOpen(false)} title='Gradient Library' header={<></>} content={<GradientLibrary selectGradient={handleSelectGradient} />} />
        </div>
      </>
    );
  }
});

interface IGradientLibraryProps {
  selectGradient: (gradient: string[]) => void;
}

function GradientLibrary({ selectGradient }: IGradientLibraryProps) {
  const theme = useTheme();

  const useStyles = createUseStyles({
    gradientItem: {
      width: 500,
      height: 100,
      borderRadius: 25,
      cursor: 'pointer',
      transition: 'transform 300ms',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), gap: theme.spacing(4), overflow: 'auto' }}>
        {GradientList.map((gradient, index) => (
          <div
            key={gradient[0] + 'index' + index}
            className={classes.gradientItem}
            style={{ background: `linear-gradient(90deg, ${gradient[0]}, ${gradient[1]})` }}
            onClick={() => selectGradient(gradient)}
          ></div>
        ))}
      </div>
    </>
  );
}
