export function timeout(ms: number) {
  let timerId: NodeJS.Timeout;
  let cancel: (() => void) | undefined;

  const promise = new Promise<void>((resolve, reject) => {
    timerId = setTimeout(() => {
      resolve();
    }, ms);

    cancel = () => {
      clearTimeout(timerId); // Cancel the timeout
    };
  });

  // Attach the `cancel` method to the promise
  (promise as any).cancel = cancel;
  return promise as Promise<void> & { cancel?: () => void };
}
