import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { convertToHex, hsvToRGB, convertToRGB, getColorName, rgbColor } from '../../utils/HexToRGBA';
import { clamp, mapRange } from '../../utils/ClampNumber';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ColorizeIcon from '@mui/icons-material/Colorize';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { createUseStyles } from 'react-jss';
import { ColorObject } from './ColorObject';
import tinycolor, { Instance } from 'tinycolor2';
import { hslToRgb } from '@mui/system';
import Wrapper from './Wrapper';
import ColorIndicator from './components/ColorIndicator';
import PercentageInput from './components/PercentageInput';
import { debounce } from '../../utils/Debounce';
import ColorInput from './components/ColorInput';

interface IPosCords {
  x: number;
  y: number;
}

export interface IColorSwatches {
  colorList: IColor[];
}

interface IColor {
  name: string;
  colorCode: string;
}

export interface IColorPickerProps {
  defaultColor: string;
  onColorChange: (s: string) => void;
  onCommitColor?: (s: string) => void;
  swatches?: IColorSwatches;
  showSwatches?: boolean;
  isCompact?: boolean;
}

const tempSwatches: IColorSwatches = {
  colorList: [
    {
      colorCode: '#CEC5ED',
      name: 'Creator Lavender',
    },
    {
      colorCode: '#F5b800',
      name: 'Creator Amber',
    },
    {
      colorCode: '#Ff9b58',
      name: 'Creator Peach',
    },
    {
      colorCode: '#Ff6600',
      name: 'Creator Orange',
    },
    {
      colorCode: '#BA2E3E',
      name: 'Creator Crimson',
    },
    {
      colorCode: '#4B3CBE',
      name: 'Creator Violet',
    },
    {
      colorCode: '#8ECCFF',
      name: 'Creator Sky',
    },
    {
      colorCode: '#0099FF',
      name: 'Creator Sea',
    },
    {
      colorCode: '#B3EC90',
      name: 'Creator Mint',
    },
    {
      colorCode: '#6EBE3C',
      name: 'Creator Jasmine',
    },
  ],
};

//TODO: reformat the colors of this component to put the colors in an object instead of their own hooks.

export default function ColorPicker({ defaultColor, onColorChange, onCommitColor, swatches, showSwatches = true, isCompact = false }: IColorPickerProps) {
  swatches = tempSwatches;

  const theme = useTheme();
  const alphaRef = React.useRef<HTMLDivElement>(null);
  const saturationRef = React.useRef<HTMLDivElement>(null);
  const hueRef = React.useRef<HTMLDivElement>(null);
  const height = 180;
  const saturationWidth = 180;
  const saturationHeight = height;

  const colorButtonRef = React.useRef<HTMLDivElement>(null);

  const [colorModel, setColorModel] = useState<'Hex' | 'Hex8' | 'RGB' | 'HSL' | 'HSV'>('Hex');
  const [alphaPickerPos, setAlphaPickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [huePicker, setHuePickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [sPicker, setsPickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [hueColor, setHueColor] = useState<Instance>(tinycolor(defaultColor));
  const [alphaValue, setAlphaValue] = useState(tinycolor(defaultColor).getAlpha() * 100);
  const [mainColor, setMainColor] = useState<Instance>(tinycolor(defaultColor));
  const [colors, setColors] = useState<ColorObject>(new ColorObject(defaultColor));

  const [isSatMouseDown, setSatMouseDown] = useState(false);
  const [isHueMouseDown, setHueMouseDown] = useState(false);
  const [isAlphaMouseDown, setAlphaMouseDown] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const initColorPicker = () => {
    const hsv = mainColor.toHsv();
    const sPPos: IPosCords = { x: mapRange(hsv.s, 0, 1, 0, saturationWidth), y: mapRange(1 - hsv.v, 0, 1, 0, saturationHeight) };
    setsPickerPos(sPPos);
    setHuePickerPos({ x: 0, y: mapRange(360 - hsv.h, 0, 360, 0, height) });
    const rgb = hsvToRGB(hsv.h, 1, 1);

    setHueColor(tinycolor(`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`));
    const alphaPos = mapRange(100 - mainColor.getAlpha() * 100, 0, 100, 0, height);
    setAlphaPickerPos({ x: 0, y: alphaPos });
  };

  const handleSaturationMouseDown = () => {
    setSatMouseDown(true);
  };

  const handleHueMouseDown = () => {
    setHueMouseDown(true);
  };

  const handleAlphaMouseDown = () => {
    setAlphaMouseDown(true);
  };

  const handleDocumentMouseUp = () => {
    setSatMouseDown(false);
    setHueMouseDown(false);
    setAlphaMouseDown(false);
  };

  const handleSaturationMouseMove = (event: any) => {
    const rect = saturationRef.current?.getBoundingClientRect();
    if (!rect) return;

    let x = event.clientX - rect.x;
    x = x > 0 ? x : 0;
    let y = event.clientY - rect.y;
    y = y > 0 ? y : 0;

    y = clamp(y, 0, saturationHeight);
    x = clamp(x, 0, saturationWidth);

    setsPickerPos({ x: x, y: y });
    const rgbString = getFinalColorAtPos(x, y);
    const expColor = createExportColor(rgbString, alphaValue);
    setMainColor(tinycolor(expColor));
  };

  const handleHueMouseMove = (event: any) => {
    const rect = hueRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.x;
    const y = event.clientY - rect.y;

    const newY = clamp(y, 0, height);
    setHuePickerPos({ x: 0, y: newY });
    let hue = getHueAtPos(x, newY);

    if (hue === 0) hue = 360;

    setHueColor(tinycolor(hslToRgb(`hsl(${hue}, 100%, 50%)`)));

    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y, hue);
    const expColor = createExportColor(rgbString, alphaValue);
    setMainColor(tinycolor(expColor));
  };

  const handleAlphaMouseMove = (event: any) => {
    const rect = alphaRef.current?.getBoundingClientRect();
    if (!rect) return;

    const y = event.clientY - rect.y;
    calculateAlphaValue(y);
  };

  const getFinalColorAtPos = (x: number, y: number, hue?: number) => {
    //const hueV = hue ? hue : mainColor.toHsv().h;
    const hueV = hue ? hue : tinycolor(hueColor).toHsv().h;

    const saturation = mapRange(x, 0, saturationWidth, 0, 100) / 100;
    const value = 1 - mapRange(y, 0, saturationHeight, 0, 100) / 100;

    const rgb = hsvToRGB(hueV, saturation, value);

    const rgbString = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
    return rgbString;
  };

  const setAlphaState = (aAlphaValue: number) => {
    const alphaPos = mapRange(100 - aAlphaValue, 0, 100, 0, height);
    setAlphaPickerPos({ x: 0, y: alphaPos });
    const aValue = Math.round(aAlphaValue);
    setAlphaValue(aValue);
    return aValue;
  };

  const setAlpha = (aAlphaValue: number) => {
    const alphaPos = mapRange(100 - aAlphaValue, 0, 100, 0, height);
    setAlphaPickerPos({ x: 0, y: alphaPos });

    setAlphaValue(aAlphaValue);
    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y);
    const expColor = createExportColor(rgbString, aAlphaValue);
    setMainColor(tinycolor(expColor));
  };

  const calculateAlphaValue = (y: number) => {
    const yPos = clamp(y, 0, height);
    const alpha = Math.round(100 - mapRange(yPos, 0, height, 0, 100));

    setAlphaPickerPos({ x: 0, y: yPos });
    setAlphaValue(alpha);
    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y);
    const expColor = createExportColor(rgbString, alpha);
    setMainColor(tinycolor(expColor));
  };

  const getHueAtPos = (x: number, y: number) => {
    const hue = 360 - mapRange(y, 0, 180, 0, 360);
    return hue;
  };

  const handleInput = (aColor: Instance) => {
    const HSV = aColor.toHsv();
    if (HSV === null) return;

    setAlphaState(aColor.getAlpha() * 100);

    const sPPos: IPosCords = { x: mapRange(HSV.s, 0, 1, 0, saturationWidth), y: mapRange(1 - HSV.v, 0, 1, 0, saturationHeight) };
    setsPickerPos(sPPos);

    setHuePickerPos({ x: 0, y: mapRange(360 - HSV.h, 0, 360, 0, height) });

    setHueColor(aColor);
    setMainColor(aColor);
    commitColor(aColor.toRgbString());
    onColorChange(aColor.toHexString());
  };

  const onColorTextInputChange = (aColorValue: string) => {
    const tmpColor = tinycolor(aColorValue);

    if (aColorValue.length < 6) return;
    if (!tmpColor.isValid()) {
      return;
    }
    handleInput(tmpColor);
  };

  const handleColorModelChange = (e: any) => {
    setColorModel(e.target.value);
  };

  const commitColor = useCallback(
    debounce((aColor: string) => {
      //if (aColor === defaultColor) return; //no need to commit a color that is the same as the color saved.
      if (onCommitColor !== undefined) {
        if (aColor !== null) {
          onCommitColor(aColor);
        }
      }
    }, 100),
    [],
  );

  useEffect(() => {
    commitColor(mainColor.toRgbString());
    onColorChange(mainColor.toRgbString());
  }, [mainColor]);

  const createExportColor = (aColor: string, aAlphaValue: number) => {
    const rgb = convertToRGB(aColor);
    let newColor = '';
    if (aAlphaValue < 100) {
      newColor = `rgba(${rgb.r},${rgb.g},${rgb.b},${aAlphaValue / 100})`;
    } else {
      newColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    }
    return newColor;
  };

  const EyeDropper = () => {
    const openEyeDropper = () => {
      if (window.EyeDropper) {
        const ed = new window.EyeDropper();
        ed.open().then((color: ColorSelectionResult) => onColorTextInputChange(color.sRGBHex));
      }
    };

    if (window.EyeDropper === undefined) {
      return (
        <>
          <Tooltip title='This feature requires Chrome' placement='top' arrow={true}>
            <div>
              <IconButton aria-label='eyedropper' disabled>
                <ColorizeIcon />
              </IconButton>
            </div>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <button aria-label='eyedropper' onClick={openEyeDropper} style={{ padding: 0, backgroundColor: 'unset', color: theme.palette.text.primary, cursor: 'pointer' }}>
          <ColorizeIcon />
        </button>
      </>
    );
  };

  const useStyles = createUseStyles({
    inputGroup: {
      backgroundColor: theme.palette.bgTwo.main,
      padding: 3,
      display: 'flex',
      gap: 2,
      '& > :first-child': {
        paddingLeft: 4,
      },
      '& > *': {
        backgroundColor: theme.palette.bgThree.main,
        padding: 2,
        '&:focus-visible': {
          outline: '1px solid #8f8f8f',
        },
      },
    },
    colorGroup: {
      padding: 3,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& > *': {
        backgroundColor: theme.palette.bgThree.main,
        padding: 2,
        '&:focus-visible': {
          outline: '1px solid #8f8f8f',
        },
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const newColor = new ColorObject(mainColor.toRgbString());
    setColors(newColor);
  }, [mainColor]);

  useEffect(() => {
    initColorPicker();

    document.addEventListener('mouseup', handleDocumentMouseUp, { passive: false });
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.getElementById('color-picker-wrapper')?.remove();
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      if (isSatMouseDown) {
        handleSaturationMouseMove(event);
      } else if (isHueMouseDown) {
        handleHueMouseMove(event);
      } else if (isAlphaMouseDown) {
        handleAlphaMouseMove(event);
      }
    };

    if (isOpen) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isOpen, isSatMouseDown, isHueMouseDown, isAlphaMouseDown]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ display: 'flex', position: 'relative', width: '100%', alignItems: 'center', gap: 20 }}>
        <div className={classes.inputGroup}>
          <div className={classes.colorGroup}>
            <ColorIndicator ref={colorButtonRef} colorString={mainColor.toRgbString()} handleOnClick={() => setIsOpen(!isOpen)} />
            <ColorInput colorModel={'Hex'} color={mainColor} setColor={handleInput} />
          </div>
          <PercentageInput defaultValue={alphaValue} handleChange={setAlpha} />
        </div>
      </div>
      {colorButtonRef.current && (
        <Wrapper wrapper={isCompact} isWrapperOpen={isOpen} onClose={() => setIsOpen(!isOpen)} parentElement={colorButtonRef.current}>
          <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
            <div
              ref={saturationRef}
              style={{
                width: saturationWidth,
                height: saturationHeight,
                position: 'relative',
                backgroundColor: hueColor.toRgbString(),
                backgroundImage: 'url("https://colorpicker.me/image/sv-map.png")',
                backgroundSize: 'cover',
                userSelect: 'none',
                cursor: 'pointer',
              }}
              onMouseDown={handleSaturationMouseDown}
              onMouseUp={handleSaturationMouseMove}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 99999,
                  backgroundColor: colors.rgbString,
                  outline: '2px solid white',
                  outlineOffset: -1,
                  position: 'absolute',
                  top: sPicker.y - 5,
                  left: sPicker.x - 5,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
            <div style={{ position: 'relative' }}>
              <div
                ref={hueRef}
                style={{ width: 20, height: 180, backgroundImage: 'url(https://colorpicker.me/image/hue-map.png)', backgroundSize: 'contain', cursor: 'pointer' }}
                onMouseDown={handleHueMouseDown}
                onMouseUp={handleHueMouseMove}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: huePicker.y,
                  left: huePicker.x,
                  width: '100%',
                  height: 4,
                  background: hueColor.toRgbString(),
                  outline: '2px solid white',
                  borderRadius: 2,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
            <div
              ref={alphaRef}
              style={{
                position: 'relative',
                width: 20,
                height: 180,
                backgroundColor: '#FFF',
                backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%), 
              linear-gradient(135deg, #ccc 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, #ccc 75%),
              linear-gradient(135deg, transparent 75%, #ccc 75%)`,
                backgroundSize: '20px 20px',
                backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
                cursor: 'pointer',
              }}
              onMouseDown={handleAlphaMouseDown}
              onMouseUp={handleAlphaMouseMove}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))',
                  backgroundColor: 'transparent',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: alphaPickerPos.y,
                  left: alphaPickerPos.x,
                  width: '100%',
                  height: 4,
                  background: '#909090',
                  outline: '2px solid white',
                  borderRadius: 2,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: theme.palette.text.primary, marginTop: 10 }}>
            <Select id='color-models' variant='standard' value={colorModel} onChange={handleColorModelChange} sx={{ width: 56 }}>
              <MenuItem value='Hex'>Hex</MenuItem>
              <MenuItem value='RGB'>RGB</MenuItem>
              <MenuItem value='HSL'>HSL</MenuItem>
              <MenuItem value='HSV'>HSV</MenuItem>
            </Select>
            <div className={classes.inputGroup}>
              <ColorInput colorModel={colorModel} color={mainColor} setColor={handleInput} />
              <PercentageInput defaultValue={alphaValue} handleChange={setAlpha} />
            </div>
            <EyeDropper />
          </div>

          {showSwatches && swatches.colorList.length > 0 && (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 24px)', gridAutoRows: 24, justifyContent: 'space-between', gap: 16, marginTop: theme.spacing(6) }}>
              {swatches.colorList.map((color: IColor, index: number) => (
                <ColorSwatch key={index} swatch={color} currentColor={mainColor.toHexString()} selectColor={handleInput} />
              ))}
            </div>
          )}
        </Wrapper>
      )}
    </div>
  );
}

interface IColorSwatch {
  swatch: IColor;
  currentColor?: string;
  selectColor: (color: Instance) => void;
}

function ColorSwatch({ swatch, currentColor, selectColor }: IColorSwatch) {
  interface IStyleProps {
    swatchColor: string;
    currentColor: string;
  }

  const useStyles = createUseStyles({
    colorBox: {
      height: 24,
      width: 24,
      backgroundColor: swatch.colorCode,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset',
      cursor: 'pointer',
      display: 'grid',
      placeItems: 'center',
      outlineOffset: 2,
      outline: ({ swatchColor, currentColor }: IStyleProps) => (swatchColor.toLowerCase() === currentColor.toLowerCase() ? `2px solid ${swatchColor}` : 'none'),
    },
    tooltip: {
      textTransform: 'capitalize',
    },
  });
  const classes = useStyles({ swatchColor: swatch.colorCode, currentColor: currentColor ? currentColor : '' });

  return (
    <>
      <Tooltip title={swatch.name.length > 0 ? swatch.name : getColorName(swatch.colorCode)} classes={{ tooltip: classes.tooltip }} placement='top' arrow={true}>
        <div className={classes.colorBox} onClick={() => selectColor(tinycolor(swatch.colorCode))}></div>
      </Tooltip>
    </>
  );
}
