import React, { forwardRef } from 'react';

interface IColorIndicatorProps {
  width?: number;
  height?: number;
  colorString: string;
  handleOnClick: () => void;
}

// Wrap the component in forwardRef to handle the ref
const ColorIndicator = forwardRef<HTMLDivElement, IColorIndicatorProps>(({ width = 15, height = 15, colorString, handleOnClick }, ref) => {
  return (
    <>
      <div
        style={{
          height: width,
          width: height,
          position: 'relative',
          cursor: 'pointer',
          boxSizing: 'border-box',
        }}
        onClick={handleOnClick}
        ref={ref}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: colorString,
            zIndex: '1',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#FFF',
            backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%), 
          linear-gradient(135deg, #ccc 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #ccc 75%),
          linear-gradient(135deg, transparent 75%, #ccc 75%)`,
            backgroundSize: '10px 10px',
            backgroundPosition: '0 0, 5px 0, 5px -5px, 0px 5px',
            zIndex: '0',
          }}
        ></div>
      </div>
    </>
  );
});

ColorIndicator.displayName = 'ColorIndicator';
export default ColorIndicator;
