import React from 'react';
import { TPropertyValueView } from '../../../services/models/PropertyViewModel';
import { useStore } from '../../../store/store';
import { runInAction } from 'mobx';
import CompletionRequirements from './CompletionRequirements';

interface IPageCompletionRequirementsProps {
  completionRequirements: TPropertyValueView;
}
export default function PageCompletionRequirements({ completionRequirements }: IPageCompletionRequirementsProps) {
  const { courseStore } = useStore();
  const { courseViewModel, pagePropertiesPageID } = courseStore;

  const updatePageRequirmentColor = (s: string) => {
    if (courseViewModel === undefined || pagePropertiesPageID === undefined) return;

    let text = 'Optional';
    const optionText = completionRequirements.options?.find((x) => x.value == s)?.text;
    if (optionText) {
      text = optionText;
    }
    runInAction(() => {
      if (courseViewModel.pages.items[pagePropertiesPageID].data.completionRequirements) {
        courseViewModel.pages.items[pagePropertiesPageID].data.completionRequirements.value = text;
      }
    });
  };

  //const colors = ['#8ECCFF', '#FF6600', '#FFDC7E'];
  return (
    <>
      <CompletionRequirements property={completionRequirements} onChange={updatePageRequirmentColor} />
    </>
  );
}
