import React, { SVGProps } from 'react';

const AccordionIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2C2.89543 2 2 2.89543 2 4V6C2 7.10457 2.89543 8 4 8H20C21.1046 8 22 7.10457 22 6V4C22 2.89543 21.1046 2 20 2H4ZM17.5 7V5.5H16V4.5H17.5V3H18.5V4.5H20V5.5H18.5V7H17.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 9C2.89543 9 2 9.89543 2 11V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V11C22 9.89543 21.1046 9 20 9H4ZM17.5 14V12.5H16V11.5H17.5V10H18.5V11.5H20V12.5H18.5V14H17.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 16C2.89543 16 2 16.8954 2 18V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V18C22 16.8954 21.1046 16 20 16H4ZM17.5 21V19.5H16V18.5H17.5V17H18.5V18.5H20V19.5H18.5V21H17.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

const AccordionSectionIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 3.04993C2.89543 3.04993 2 3.94536 2 5.04993V9.04993H22V5.04993C22 3.94536 21.1046 3.04993 20 3.04993H4ZM20 5.54993V6.54993H16V5.54993H20Z'
        fill='currentColor'
      />
      <rect x='3' y='4' width='18' height='16' rx='1' stroke='currentColor' strokeWidth='2' />
    </svg>
  );
};

const SingleChoiceIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
        fill='inherit'
      />
      <path d='M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z' fill='inherit' />
    </svg>
  );
};
const SingleChoiceAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
        fill='inherit'
      />
    </svg>
  );
};

const MultipleChoiceIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17.99 9L16.58 7.58L9.99 14.17L7.41 11.6L5.99 13.01L9.99 17L17.99 9Z'
        fill='inherit'
      />
    </svg>
  );
};
const MultipleChoiceAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.5981 5V19H5.59814V5H19.5981ZM19.5981 3H5.59814C4.49814 3 3.59814 3.9 3.59814 5V19C3.59814 20.1 4.49814 21 5.59814 21H19.5981C20.6981 21 21.5981 20.1 21.5981 19V5C21.5981 3.9 20.6981 3 19.5981 3Z'
        fill='inherit'
      />
    </svg>
  );
};

const TrueFalseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1459 18.9522L14.1317 18.9664L10.7214 15.5562L12.2276 14.05L14.1459 15.9682L17.684 12.4443L19.1902 13.9505L14.1601 18.9806L14.1459 18.9522Z'
        fill='inherit'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 20L20 4V20H4ZM2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4ZM7.50848 6.42884L5.50497 4.42533L3.99878 5.93152L6.00229 7.93503L3.99878 9.93854L5.50497 11.4447L7.50848 9.44121L9.51199 11.4447L11.0182 9.93854L9.01466 7.93503L11.0182 5.93152L9.51199 4.42533L7.50848 6.42884Z'
        fill='inherit'
      />
    </svg>
  );
};

const TrueFalseAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 4L4 20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z'
        fill='inherit'
      />
    </svg>
  );
};

const JustifyCenterIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 13H2V11H22V13Z' fill='inherit' />
      <path d='M7 14L7 17L17 17L17 14L7 14Z' fill='inherit' />
      <path d='M7 7L7 10L17 10L17 7L7 7Z' fill='inherit' />
    </svg>
  );
};
const JustifyStartIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 2L22 2L22 4L2 4L2 2Z' fill='inherit' />
      <path d='M17 8L17 5L7 5L7 8L17 8Z' fill='inherit' />
      <path d='M17 12L17 9L7 9L7 12L17 12Z' fill='inherit' />
    </svg>
  );
};
const JustifyEndIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M7 16L7 19L17 19L17 16L7 16Z' fill='inherit' />
      <path d='M7 12L7 15L17 15L17 12L7 12Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceBetweenIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 17L7 20L17 20L17 17L7 17Z' fill='inherit' />
      <path d='M7 4L7 7L17 7L17 4L7 4Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceAroundIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 16L7 19L17 19L17 16L7 16Z' fill='inherit' />
      <path d='M7 5L7 8L17 8L17 5L7 5Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceEvenlyIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 14L7 17L17 17L17 14L7 14Z' fill='inherit' />
      <path d='M7 7L7 10L17 10L17 7L7 7Z' fill='inherit' />
    </svg>
  );
};

const FullscreenIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z' fill='inherit' />
    </svg>
  );
};

const WikiIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.95 21.05C5.58333 21.6833 6.35 22 7.25 22H20V20.5C19.5667 20.5 19.2083 20.325 18.925 19.975C18.6417 19.625 18.5 19.2167 18.5 18.75C18.5 18.2833 18.6417 17.875 18.925 17.525C19.2083 17.175 19.5667 17 20 17V2H7.25C6.35 2 5.58333 2.31667 4.95 2.95C4.31667 3.58333 4 4.35 4 5.25V18.75C4 19.65 4.31667 20.4167 4.95 21.05ZM17.475 20.5H7.25C6.76667 20.5 6.35417 20.3292 6.0125 19.9875C5.67083 19.6458 5.5 19.2333 5.5 18.75C5.5 18.2667 5.67083 17.8542 6.0125 17.5125C6.35417 17.1708 6.76667 17 7.25 17H17.5C17.3333 17.25 17.2083 17.5208 17.125 17.8125C17.0417 18.1042 17 18.4167 17 18.75C17 19.0667 17.0417 19.375 17.125 19.675C17.2083 19.975 17.325 20.25 17.475 20.5ZM7.19092 6.008L9.19598 13.0154H10.6399L11.9709 8.43391H12.0256L13.3601 13.0154H14.804L16.809 6.008H15.1906L14.0307 10.8872H13.9691L12.6929 6.008H11.3071L10.0274 10.8769H9.96926L8.80934 6.008H7.19092Z'
        fill='inherit'
      />
    </svg>
  );
};

//brukt i truefalse og multiple choice
const CheckIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.79995 15.1929L4.9535 11.3464L4.59995 10.9929L4.2464 11.3464L2.8464 12.7464L2.49284 13.1L2.8464 13.4535L8.4464 19.0535L8.79995 19.4071L9.1535 19.0535L21.1535 7.05354L21.5071 6.69999L21.1535 6.34643L19.7535 4.94643L19.4 4.59288L19.0464 4.94643L8.79995 15.1929Z'
        fill='inherit'
        stroke='inherit'
      />
    </svg>
  );
};

//brukt i truefalse
const FalseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.3536 6.76355L19.7071 6.41L19.3536 6.05645L17.9436 4.64645L17.59 4.29289L17.2364 4.64645L12 9.88289L6.76355 4.64645L6.41 4.29289L6.05645 4.64645L4.64645 6.05645L4.29289 6.41L4.64645 6.76355L9.88289 12L4.64645 17.2364L4.29289 17.59L4.64645 17.9436L6.05645 19.3536L6.41 19.7071L6.76355 19.3536L12 14.1171L17.2364 19.3536L17.59 19.7071L17.9436 19.3536L19.3536 17.9436L19.7071 17.59L19.3536 17.2364L14.1171 12L19.3536 6.76355Z'
        fill='inherit'
        stroke='inherit'
      />
    </svg>
  );
};

//brukt i CoursePageItem
const PersonPinCircleDashed: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  let size = '24';

  if (props.fontSize) {
    switch (props.fontSize) {
      case 'small':
        size = '20';
        break;
      case 'medim':
        size = '24';
        break;
      case 'large':
        size = '35';
        break;
      default:
        size = '24';
        break;
    }
  }

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='transparent' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 15C12.9333 15 13.775 14.7708 14.525 14.3125C15.275 13.8542 15.8666 13.25 16.3 12.5C15.7166 12.0167 15.0583 11.6458 14.325 11.3875C13.5916 11.1292 12.8166 11 12 11C11.1833 11 10.4083 11.1292 9.67495 11.3875C8.94162 11.6458 8.28328 12.0167 7.69995 12.5C8.13328 13.25 8.72495 13.8542 9.47495 14.3125C10.225 14.7708 11.0666 15 12 15ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8041 9.02083 14 8.55 14 8C14 7.45 13.8041 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9791 6.19583 10.5875 6.5875C10.1958 6.97917 9.99995 7.45 9.99995 8C9.99995 8.55 10.1958 9.02083 10.5875 9.4125C10.9791 9.80417 11.45 10 12 10Z'
        fill='currentColor'
      />
      <path d='M12.0371 20.5C0.537283 12.5 5.03732 3 12.0372 3C19.0372 3 23.5374 12.5 12.0371 20.5Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' strokeDasharray='4 2' />
    </svg>
  );
};
interface DragIndicatorIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
//brukt i EnquiryContentCategorization
const DragIndicatorIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z'
        fill='inherit'
      />
    </svg>
  );
};

//brukt sammen med submitt knappen
const ReplayIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 6.83948V2.83948L7 7.83948L12 12.8395V8.83948C15.31 8.83948 18 11.5295 18 14.8395C18 18.1495 15.31 20.8395 12 20.8395C8.69 20.8395 6 18.1495 6 14.8395H4C4 19.2595 7.58 22.8395 12 22.8395C16.42 22.8395 20 19.2595 20 14.8395C20 10.4195 16.42 6.83948 12 6.83948Z'
        fill='inherit'
      />
    </svg>
  );
};

//brukt sammen med submitt knappen
const DuplicatePageIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='currentColor'>
      <path d='M3 19H20V21H3C1.9 21 1 20.1 1 19V6H3V19ZM23 6V15C23 16.1 22.1 17 21 17H7C5.9 17 5 16.1 5 15L5.01 6C5.01 4.9 5.9 4 7 4H21C22.1 4 23 4.9 23 6ZM7 15H21V6H7V15Z' fill='inherit' />
      <path d='M15 14.5H13V11.5H10V9.5H13V6.5H15V9.5H18V11.5H15V14.5Z' fill='inherit' />
    </svg>
  );
};

//brukt sammen med submitt knappen
const CopyPageIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='currentColor'>
      <path
        id='Vector'
        d='M3 19H20V21H3C1.9 21 1 20.1 1 19V6H3V19ZM23 6V15C23 16.1 22.1 17 21 17H7C5.9 17 5 16.1 5 15L5.01 6C5.01 4.9 5.9 4 7 4H21C22.1 4 23 4.9 23 6ZM7 15H21V6H7V15Z'
        fill='inherit'
      />
    </svg>
  );
};

//brukt sammen med submitt knappen
const CopyPageSubpageIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='currentColor'>
      <path d='M3 19H20V21H3C1.9 21 1 20.1 1 19V6H3V19ZM23 6V15C23 16.1 22.1 17 21 17H7C5.9 17 5 16.1 5 15L5.01 6C5.01 4.9 5.9 4 7 4H21C22.1 4 23 4.9 23 6ZM7 15H21V6H7V15Z' fill='inherit' />
      <path d='M10.4001 7.75H8.6001V9.5H10.4001V7.75Z' fill='inherit' />
      <path d='M15.8001 11.25H8.6001V13H15.8001V11.25Z' fill='inherit' />
      <path d='M19.4001 11.25H17.6001V13H19.4001V11.25Z' fill='inherit' />
      <path d='M19.4001 7.75H12.2001V9.5H19.4001V7.75Z' fill='inherit' />
    </svg>
  );
};

//DnD Drag Element
const DnDDragElement: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.725 22C13.3614 22 13.0129 21.9318 12.6796 21.7955C12.3462 21.6591 12.0508 21.4621 11.7932 21.2046L7.79321 17.1818C7.59624 16.9849 7.5167 16.7538 7.55457 16.4887C7.59245 16.2235 7.70988 15.9849 7.90685 15.7727C8.11897 15.5606 8.37654 15.4167 8.67957 15.3409C8.9826 15.2652 9.28563 15.2652 9.58867 15.3409L11.1568 15.7955V8.36365C11.1568 8.10607 11.244 7.89017 11.4182 7.71592C11.5925 7.54168 11.8084 7.45456 12.0659 7.45456C12.3235 7.45456 12.5394 7.54168 12.7137 7.71592C12.8879 7.89017 12.975 8.10607 12.975 8.36365V17C12.975 17.303 12.8576 17.5417 12.6228 17.7159C12.3879 17.8902 12.119 17.9394 11.8159 17.8637L10.7478 17.5682L13.0887 19.9091C13.1644 19.9849 13.2591 20.0493 13.3728 20.1023C13.4864 20.1553 13.6038 20.1818 13.725 20.1818H17.5205C18.0205 20.1818 18.4485 20.0038 18.8046 19.6477C19.1606 19.2917 19.3387 18.8637 19.3387 18.3637V14.7273C19.3387 14.4697 19.4258 14.2538 19.6 14.0796C19.7743 13.9053 19.9902 13.8182 20.2478 13.8182C20.5053 13.8182 20.7212 13.9053 20.8955 14.0796C21.0697 14.2538 21.1568 14.4697 21.1568 14.7273V18.3637C21.1568 19.3637 20.8008 20.2197 20.0887 20.9318C19.3765 21.644 18.5205 22 17.5205 22H13.725ZM14.7932 11.0909C15.0508 11.0909 15.2667 11.178 15.4409 11.3523C15.6152 11.5265 15.7023 11.7424 15.7023 12V14.7273C15.7023 14.9849 15.6152 15.2008 15.4409 15.375C15.2667 15.5493 15.0508 15.6364 14.7932 15.6364C14.5356 15.6364 14.3197 15.5493 14.1455 15.375C13.9712 15.2008 13.8841 14.9849 13.8841 14.7273V12C13.8841 11.7424 13.9712 11.5265 14.1455 11.3523C14.3197 11.178 14.5356 11.0909 14.7932 11.0909ZM17.5205 12C17.7781 12 17.994 12.0871 18.1682 12.2614C18.3425 12.4356 18.4296 12.6515 18.4296 12.9091V14.7273C18.4296 14.9849 18.3425 15.2008 18.1682 15.375C17.994 15.5493 17.7781 15.6364 17.5205 15.6364C17.2629 15.6364 17.047 15.5493 16.8728 15.375C16.6985 15.2008 16.6114 14.9849 16.6114 14.7273V12.9091C16.6114 12.6515 16.6985 12.4356 16.8728 12.2614C17.047 12.0871 17.2629 12 17.5205 12Z'
        fill='currentColor'
      />
      <path
        d='M9.375 9.5H5C4.44772 9.5 4 9.05228 4 8.5V3.5C4 2.94772 4.44772 2.5 5 2.5H17C17.5523 2.5 18 2.94772 18 3.5V8.5C18 9.05228 17.5523 9.5 17 9.5H14.7508'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
    </svg>
  );
};

//DnD Drop Element
const DnDDropElement: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.90476 22C4.38095 22 3.93254 21.8135 3.55952 21.4405C3.18651 21.0675 3 20.619 3 20.0952V10.5714C3 10.0476 3.18651 9.59921 3.55952 9.22619C3.93254 8.85317 4.38095 8.66667 4.90476 8.66667H7.7619C8.03175 8.66667 8.25794 8.75794 8.44048 8.94048C8.62302 9.12302 8.71429 9.34921 8.71429 9.61905C8.71429 9.88889 8.62302 10.1151 8.44048 10.2976C8.25794 10.4802 8.03175 10.5714 7.7619 10.5714H4.90476V20.0952H18.2381V10.5714H15.381C15.1111 10.5714 14.8849 10.4802 14.7024 10.2976C14.5198 10.1151 14.4286 9.88889 14.4286 9.61905C14.4286 9.34921 14.5198 9.12302 14.7024 8.94048C14.8849 8.75794 15.1111 8.66667 15.381 8.66667H18.2381C18.7619 8.66667 19.2103 8.85317 19.5833 9.22619C19.9563 9.59921 20.1429 10.0476 20.1429 10.5714V20.0952C20.1429 20.619 19.9563 21.0675 19.5833 21.4405C19.2103 21.8135 18.7619 22 18.2381 22H4.90476ZM12.5238 13.5952L13.381 12.7381C13.5556 12.5635 13.7738 12.4762 14.0357 12.4762C14.2976 12.4762 14.5238 12.5714 14.7143 12.7619C14.8889 12.9365 14.9762 13.1587 14.9762 13.4286C14.9762 13.6984 14.8889 13.9206 14.7143 14.0952L12.2381 16.5714C12.0476 16.7619 11.8254 16.8571 11.5714 16.8571C11.3175 16.8571 11.0952 16.7619 10.9048 16.5714L8.42857 14.0952C8.25397 13.9206 8.1627 13.7024 8.15476 13.4405C8.14682 13.1786 8.2381 12.9524 8.42857 12.7619C8.60317 12.5873 8.8254 12.5 9.09524 12.5C9.36508 12.5 9.5873 12.5873 9.7619 12.7619L10.619 13.5952V2.95238C10.619 2.68254 10.7103 2.45635 10.8929 2.27381C11.0754 2.09127 11.3016 2 11.5714 2C11.8413 2 12.0675 2.09127 12.25 2.27381C12.4325 2.45635 12.5238 2.68254 12.5238 2.95238V13.5952Z'
        fill='inherit'
      />
    </svg>
  );
};

//AI Voice
const AIVoiceIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='currentColor'>
      <g id='AI Voice'>
        <g id='Group 1112'>
          <path
            id='Subtract'
            fillRule='inherit'
            clipRule='inherit'
            d='M16.0069 19.9699C15.9015 19.9896 15.7929 20 15.6818 20H5.31818C4.36818 20 3.59091 19.2421 3.59091 18.3158V14.9474C2.15727 14.9474 1 13.8189 1 12.4211C1 11.0232 2.15727 9.89474 3.59091 9.89474V8.21053C3.59091 7.28421 4.36818 6.52632 5.31818 6.52632H7.90909C7.90909 5.12842 9.06636 4 10.5 4C11.9336 4 13.0909 5.12842 13.0909 6.52632H15.6818C16.6318 6.52632 17.4091 7.28421 17.4091 8.21053V9.76494C16.7381 10.1892 16.1502 10.7732 15.6818 11.4739V8.21053H5.31818V18.3158H15.0415C15.2901 18.9262 15.617 19.4836 16.0069 19.9699ZM6.61364 12C6.61364 12.6989 7.19227 13.2632 7.90909 13.2632C8.62591 13.2632 9.20455 12.6989 9.20455 12C9.20455 11.3011 8.62591 10.7368 7.90909 10.7368C7.19227 10.7368 6.61364 11.3011 6.61364 12ZM13.0909 13.2632C13.8077 13.2632 14.3864 12.6989 14.3864 12C14.3864 11.3011 13.8077 10.7368 13.0909 10.7368C12.3741 10.7368 11.7955 11.3011 11.7955 12C11.7955 12.6989 12.3741 13.2632 13.0909 13.2632ZM13.9545 16.6316V14.9474H7.04545V16.6316H13.9545Z'
            fill='inherit'
          />
          <g id='Vector'>
            <path d='M18 15C18 15.83 18.67 16.5 19.5 16.5C20.33 16.5 21 15.83 21 15V12.5C21 11.67 20.33 11 19.5 11C18.67 11 18 11.67 18 12.5V15Z' fill='inherit' />
            <path d='M20 20H19V18.46C17.31 18.22 16 16.76 16 15H17C17 16.38 18.12 17.5 19.5 17.5C20.88 17.5 22 16.38 22 15H23C23 16.76 21.69 18.22 20 18.46V20Z' fill='inherit' />
          </g>
        </g>
      </g>
    </svg>
  );
};

//Resize
const Resize: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_676_3724)'>
        <path
          d='M16.0568 8.46875L16.0568 3.85601L22.9573 11L16.0568 18.144L16.0568 13.5313L16.0568 8.46875ZM7.94318 18.144L1.04274 11L7.94318 3.85601L7.94318 8.46875L7.94318 13.5313L7.94318 18.144Z'
          stroke='black'
          strokeWidth='0.6'
        />
      </g>
      <defs>
        <clipPath id='clip0_676_3724'>
          <rect width={size} height={size} fill='inherit' />
        </clipPath>
      </defs>
    </svg>
  );
};

export {
  AccordionIcon,
  AccordionSectionIcon,
  SingleChoiceIcon,
  SingleChoiceAlternativeIcon,
  MultipleChoiceIcon,
  MultipleChoiceAlternativeIcon,
  TrueFalseIcon,
  TrueFalseAlternativeIcon,
  JustifyCenterIcon,
  JustifyStartIcon,
  JustifyEndIcon,
  JustifySpaceBetweenIcon,
  JustifySpaceAroundIcon,
  JustifySpaceEvenlyIcon,
  FullscreenIcon,
  WikiIcon,
  CheckIcon,
  FalseIcon,
  PersonPinCircleDashed,
  DragIndicatorIcon,
  ReplayIcon,
  DuplicatePageIcon,
  CopyPageIcon,
  CopyPageSubpageIcon,
  DnDDragElement,
  DnDDropElement,
  AIVoiceIcon,
  Resize,
};
