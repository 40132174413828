import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import tinycolor, { Instance } from 'tinycolor2';

interface IColorInputProps {
  colorModel: 'Hex' | 'Hex8' | 'RGB' | 'HSL' | 'HSV';
  color: Instance;
  setColor: (color: tinycolor.Instance) => void;
}

export default function ColorInput({ colorModel, color, setColor }: IColorInputProps) {
  const [tempHexInput, setTempHexInput] = useState(color.toHexString());

  const commonStyle = {
    width: 27,
    border: 0,
    color: 'white',
    fontSize: 15,
    textTransform: 'uppercase' as const,
  };

  const colorValueMap = {
    Hex: { values: [tempHexInput], labels: ['Hex'] },
    Hex8: { values: [tempHexInput], labels: ['Hex8'] },
    RGB: {
      values: [color.toRgb().r, color.toRgb().g, color.toRgb().b],
      labels: ['Red', 'Green', 'Blue'],
    },
    HSL: {
      values: [Math.round(color.toHsl().h), Math.round(color.toHsl().s * 100), Math.round(color.toHsl().l * 100)],
      labels: ['Hue', 'Saturation', 'Lightness'],
    },
    HSV: {
      values: [Math.round(color.toHsv().h), Math.round(color.toHsv().s * 100), Math.round(color.toHsv().v * 100)],
      labels: ['Hue', 'Saturation', 'Value'],
    },
  };

  const { values: colorValues, labels } = colorValueMap[colorModel] || { values: [], labels: [] };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteValue = event.clipboardData.getData('Text').trim();
    const colorFromPaste = tinycolor(pasteValue);

    if (colorFromPaste.isValid()) {
      setColor(colorFromPaste);
      setTempHexInput(colorFromPaste.toHexString());
      event.preventDefault();
    }
  };

  const handleEnter = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      let aValue = (event.target as HTMLInputElement).value.replace('#', '');
      aValue = aValue.replace('#', '');
      if (aValue.length === 1) {
        aValue = aValue.repeat(6);
        aValue = '#' + aValue;
      } else if (aValue.length === 2) {
        aValue = aValue.repeat(3);
        aValue = '#' + aValue;
      } else if (aValue.length === 3) {
        aValue = tinycolor(aValue).toHexString();
      } else if (aValue.length > 3) {
        aValue = '#' + aValue + aValue.slice(-1).repeat(6 - aValue.length);
      } else {
        aValue = '#ff6600';
      }

      handleChange(index, aValue);
    }
  };

  const handleChange = (index: number, newValue: string) => {
    if (colorModel === 'Hex' || colorModel === 'Hex8') {
      setTempHexInput(newValue);
      const isValidHex = /^#?[0-9A-Fa-f]{3,6}$/.test(newValue);

      if (isValidHex && newValue.length === 7) {
        setColor(tinycolor(newValue));
      }
    } else {
      const parsedValue = newValue === '' ? 0 : parseFloat(newValue);

      if (colorModel === 'RGB') {
        const rgb = color.toRgb();
        const updatedRgb = {
          r: index === 0 ? parsedValue : rgb.r,
          g: index === 1 ? parsedValue : rgb.g,
          b: index === 2 ? parsedValue : rgb.b,
        };
        setColor(tinycolor(updatedRgb));
      } else if (colorModel === 'HSL') {
        const hsl = color.toHsl();
        const updatedHsl = {
          h: index === 0 ? parsedValue : hsl.h,
          s: index === 1 ? parsedValue / 100 : hsl.s,
          l: index === 2 ? parsedValue / 100 : hsl.l,
        };
        setColor(tinycolor(updatedHsl));
      } else if (colorModel === 'HSV') {
        const hsv = color.toHsv();
        const updatedHsv = {
          h: index === 0 ? parsedValue : hsv.h,
          s: index === 1 ? parsedValue / 100 : hsv.s,
          v: index === 2 ? parsedValue / 100 : hsv.v,
        };
        setColor(tinycolor(updatedHsv));
      }
    }
  };

  useEffect(() => {
    setTempHexInput(color.toHexString());
  }, [color]);

  return (
    <>
      {colorValues.map((value, index) => (
        <Tooltip key={index} title={labels[index] || ''} arrow>
          <input
            type='text'
            style={(colorModel === 'Hex' || colorModel === 'Hex8') && index === 0 ? { ...commonStyle, width: 93 } : commonStyle}
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onPaste={handlePaste}
            onKeyUp={index === 0 && colorValues.length < 2 ? (e) => handleEnter(index, e) : undefined}
          />
        </Tooltip>
      ))}
    </>
  );
}
