import Typography from '@mui/material/Typography/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { IDIRFilesModel } from '../../services/models/MediaLibraryModels';
import { MediaLibraryStyle } from './MediaLibraryStyle';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { IPathObject } from '../../utils/TreeList';
import { BiggerTooltip } from '../../components/tooltip/BiggerTooltip';
import { createUseStyles } from 'react-jss';
import Divider from '@mui/material/Divider/Divider';
import { Link, Tooltip, tooltipClasses } from '@mui/material';

interface IMediaFileProps {
  file: IDIRFilesModel;
  tileSize: number;
  isListView: boolean;
  select: () => void;
}

export const getIconName = (extension: string) => {
  const iconList = ['css', 'gif', 'jpg', 'js', 'mp3', 'mp4', 'pdf', 'png', 'pps', 'ppt', 'svg', 'webm'];
  const icon = iconList.find((x) => x == extension.slice(1));
  if (icon !== undefined) {
    return icon;
  } else {
    return 'default';
  }
};

export default observer(function MediaFile({ file, tileSize, isListView, select }: IMediaFileProps) {
  const { mediaLibraryStore } = useStore();
  const { selectedFile, selectFile, deleteFile, moveFile, RenameFileTrigger, isMediaLibraryOpenFromEditor, mediaFolders } = mediaLibraryStore;
  const [thumbnail, setThumbnail] = useState('');
  const [hover, setHover] = useState(false);
  const menuItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (file.metadata.tmb != undefined) {
      setThumbnail(file.metadata.tmb);
    } else {
      setThumbnail(`${process.env.PUBLIC_URL}/Icons/${getIconName(file.extension)}.svg`);
    }
  }, []);

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleLinkClick = () => {
    window.open(file.webPath, '_blank');
    handleClose();
  };

  const handleOnClick = () => {
    if (contextMenu == null) {
      selectFile(file);
    }
  };

  const handleOnDoubleClick = () => {
    if (contextMenu == null && isMediaLibraryOpenFromEditor) {
      selectFile(file);
      select();
    }
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRenameFile = () => {
    selectFile(file);
    handleClose();
    RenameFileTrigger();
  };

  const theme = useTheme();
  const useStyles = createUseStyles((theme: Theme) => ({
    mediaFileRoot: {
      outlineWidth: 3,
      outlineStyle: 'solid',
      transition: 'outline 200ms linear',
    },
  }));
  const myClasses = useStyles({ theme });
  const classes = MediaLibraryStyle({ theme });

  interface iFolderProps {
    folder: IPathObject[];
    file: IDIRFilesModel;
  }
  const Folder = ({ folder, file }: iFolderProps) => {
    return (
      <div style={{ marginLeft: 10 }}>
        {folder.map((child, index) => (
          <div key={child.name + child.path + 'index ' + index}>
            <MenuItem data-donotrender='true' onClick={() => moveFile(file, child)}>
              <KeyboardReturnIcon fontSize='small' style={{ transform: 'scaleX(-1)', marginRight: 5 }} /> {child.name}
            </MenuItem>
            <Folder folder={child.children} file={file} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div
        className={[classes.mediaFileRoot, myClasses.mediaFileRoot].join(' ')}
        style={{
          maxWidth: tileSize,
          minWidth: isListView ? '100%' : tileSize,
          height: isListView ? 40 : tileSize,
          cursor: 'pointer',
          outlineColor: selectedFile === file ? theme.palette.primary.main : 'transparent',
        }}
        onClick={handleOnClick}
        onDoubleClick={handleOnDoubleClick}
        onContextMenu={handleContextMenu}
      >
        {file.extension == '.pdf' && (
          <img
            src={'/img/NoMobileSupport.svg'}
            alt='No mobile support'
            style={{ position: 'absolute', height: isListView ? 40 : '45%', top: isListView ? '-4px' : '-5%', right: isListView ? '-5px' : '-6%' }}
            draggable={false}
          />
        )}
        <Tooltip
          title={file.name}
          arrow
          slotProps={{
            popper: {
              sx: {
                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                  marginTop: '0px',
                },
              },
            },
          }}
        >
          <div className={isListView ? classes.mediaFileContentList : classes.mediaFileContent}>
            <img src={thumbnail} alt='' style={{ height: isListView ? '100%' : '75%', width: isListView ? 'min-content' : '75%', userSelect: 'none' }} draggable={false} />

            <Typography variant='body1' noWrap sx={{ maxWidth: '90%' }}>
              {file.name}
            </Typography>
          </div>
        </Tooltip>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        >
          <MenuItem onClick={handleRenameFile}>Rename</MenuItem>
          <MenuItem onClick={handleLinkClick}>Preview</MenuItem>
          <MenuItem onClick={() => deleteFile(file.name)}>Delete</MenuItem>
          <Divider />
          <MenuItem ref={menuItemRef} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} data-donotrender='true' style={{ width: '140px', paddingRight: 0 }}>
            <ListItemText>Move to</ListItemText> <ChevronRightIcon />
            <>
              <Menu
                open={hover}
                onClose={() => setHover(false)}
                anchorEl={menuItemRef.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                data-donotrender='true'
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                PaperProps={{
                  style: {
                    maxHeight: 600,
                  },
                }}
              >
                {mediaFolders?.children.map((folder) => (
                  <div key={'1folder ' + folder.path} style={{ pointerEvents: 'auto' }}>
                    <MenuItem dense data-donotrender='true' style={{ fontWeight: 'bold' }} onClick={() => moveFile(file, folder)}>
                      {folder.name}
                    </MenuItem>
                    <Folder folder={folder.children} file={file} />
                  </div>
                ))}
              </Menu>
            </>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
});
