import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton from '@mui/material/IconButton/IconButton';
import AppBar from '@mui/material/AppBar/AppBar';
import { Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Typography from '@mui/material/Typography/Typography';
import Avatar from '@mui/material/Avatar/Avatar';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Logout from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ECreatorUserRole } from '../../../store/userStore';
import Modal from '../../modal/Modal';
import MediaLibrary from '../../../features/mediaLibrary/MediaLibrary';
import { BiggerTooltip } from '../../tooltip/BiggerTooltip';
import { createUseStyles } from 'react-jss';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WikiIcon } from '../../icons/Icons';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import WalkthroughSettings from '../../walkthroughSettings/WalkthroughSettings';

export default observer(function NavBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const { userStore, adminStore, courseStore } = useStore();
  const { /* metadata, setDarkMode, */ user, role } = userStore;
  const { simulatedUserName, resetTestOwner } = adminStore;
  const { clearCourseViewModel } = courseStore;
  const { logout } = useAuth0();
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    appBar: {
      flex: '0 1 auto',
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.bgZero.contrastText,
      background: theme.palette.bgZero.main,
      boxShadow: 'none',
    },
    darkIcon: {
      animation: '$rotate 500ms ',
      animationDirection: 'reverse',
    },
    lightIcon: {
      animation: '$rotate 500ms',
    },
  }));

  const classes = useStyles({ theme });

  const checkUserAndRole = () => {
    if (user !== undefined && role > ECreatorUserRole.noRole) {
      return true;
    }
    return false;
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name: string) {
    let newName = name;
    if (name.includes(' ')) {
      newName = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }

    return {
      sx: {
        width: 30,
        height: 30,
        bgcolor: stringToColor(name),
      },
      children: newName,
    };
  }

  const [testOwnerName, setTestOwnerName] = useState('');
  useEffect(() => {
    setTestOwnerName(simulatedUserName);
  }, [simulatedUserName]);

  return (
    <>
      <AppBar position='sticky' className={classes.appBar}>
        <Toolbar variant='dense' style={{ justifyContent: 'space-between' }}>
          <Link to={'/'} style={{ color: 'inherit', textDecoration: 'none' }} onClick={() => clearCourseViewModel()}>
            <div style={{ display: 'flex' }}>
              <img src={'/logo.png'} alt='' width={30} height={30} style={{ marginRight: 10 }} />
              <Typography variant='h6' noWrap color='inherit' fontStyle={'italic'}>
                Mentorkit Creator
              </Typography>
            </div>
          </Link>
          {testOwnerName !== '' && (
            <div>
              <span>Du er logget inn som {testOwnerName}, </span>
              <Link to={'/'} onClick={resetTestOwner} style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                Logg ut
              </Link>
            </div>
          )}
          {checkUserAndRole() ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <BiggerTooltip title='Media Library' placement='bottom'>
                <IconButton onClick={() => setIsMediaLibraryOpen(true)} style={{ color: theme.palette.common.white }}>
                  <PermMediaIcon />
                </IconButton>
              </BiggerTooltip>

              <BiggerTooltip title='Support' placement='bottom'>
                <IconButton component='a' href='https://norskinteraktiv.atlassian.net/servicedesk/customer/portal/4/group/-1' target='_blank'>
                  <HelpIcon />
                </IconButton>
              </BiggerTooltip>

              <BiggerTooltip title='Wiki' placement='bottom'>
                <IconButton component='a' href='https://norskinteraktiv.atlassian.net/wiki/spaces/CBP/pages/2557870093/Creator+Starters' target='_blank'>
                  <WikiIcon />
                </IconButton>
              </BiggerTooltip>

              <BiggerTooltip title='Account Settings'>
                <button
                  onClick={handleClick}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    padding: 0,
                    font: 'inherit',
                    cursor: 'pointer',
                    outline: 'inherit',
                    borderLeft: '1px solid',
                    borderColor: theme.palette.border.main,
                    paddingLeft: 16,
                  }}
                >
                  <Avatar src={user?.picture} {...stringAvatar(user?.name ? user.name : '')} style={{ color: theme.palette.common.white, fontSize: 16 }} />
                  Profile <ExpandMoreIcon />
                </button>
              </BiggerTooltip>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem component={Link} to={'/userAccount'}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText>My account</ListItemText>
                </MenuItem>
                {role >= ECreatorUserRole.superAdmin && (
                  <MenuItem component={Link} to={'/admin'}>
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText>Admin Panel</ListItemText>
                  </MenuItem>
                )}
                <Divider />
                {/*                 <MenuItem onClick={() => setDarkMode(!metadata.darkMode)}>
                  <ListItemIcon style={{ position: 'relative' }}>{!metadata.darkMode ? <DarkModeIcon /> : <LightModeIcon />}</ListItemIcon>
                  <ListItemText>{!metadata.darkMode ? 'Dark Mode' : 'Light Mode'}</ListItemText>
                </MenuItem>
                <Divider /> */}
                <MenuItem onClick={() => setIsDialogOpen(true)}>
                  <ListItemIcon>
                    <ModelTrainingIcon />
                  </ListItemIcon>
                  <ListItemText>Interactive Guides</ListItemText>
                </MenuItem>
                {/* <MenuItem>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </MenuItem> */}
                <MenuItem onClick={() => logout({ returnTo: window.location.origin } as any)}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>Sign Out... {user ? `(${user.name})` : ''}</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Link to={'/logout'} style={{ color: theme.palette.common.white, textDecoration: 'none' }}>
              Logg ut
            </Link>
          )}
        </Toolbar>
      </AppBar>

      <WalkthroughSettings isDialogOpen={isDialogOpen} setIsDialogOpen={() => setIsDialogOpen(false)} />
      <Modal isOpen={isMediaLibraryOpen} onClose={() => setIsMediaLibraryOpen(false)} title='Media Library' content={<MediaLibrary />} />
    </>
  );
});
