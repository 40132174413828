import React, { StrictMode } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';

const domain = process.env.REACT_APP_AUTH0_DOMAIN + '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID + '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE + '';

if (process.env.REACT_APP_ENV_LOCATION === 'prod') {
  window.addEventListener('contextmenu', (e) => e.preventDefault(), { passive: false });
  window.addEventListener('keydown', (e) => e.ctrlKey && e.key === 's' && e.preventDefault(), { passive: false });
}
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          audience: audience,
          redirect_uri: `${window.location.origin}/login`,
          scope: 'openid profile email offline_access',
        }}
        cacheLocation='localstorage'
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
      >
        <App />
      </Auth0Provider>
    </QueryParamProvider>
  </BrowserRouter>,
);
