import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { store } from './store';
import { IPagePlaceholder } from '../services/models/PageViewModel';

//TODO: Se på å flytte denne og andre lik den som en "sub store" til pagestore. Da kan også pageStore splittes opp.
export interface IMoveEnquiryObj {
  contentID: number;
  fromContentID: number;
  toContentID: number;
  toContentIndex?: number;
}
export default class EnquiryStore {
  //variables

  constructor() {
    makeAutoObservable(this);
  }

  moveEnquiryContent = (placeholder: IPagePlaceholder, enquiryContentID: number, fromContentID: number, targetContentID: number, toContentIndex?: number) => {
    agent.Enquiry.moveEnquiryContent({ contentID: enquiryContentID, fromContentID: fromContentID, toContentID: targetContentID, toContentIndex: toContentIndex })
      .then((newContent) => {
        runInAction(() => {
          const currentPlaceholder = store.pageStore.coursePage?.placeholders.find((x) => x.pagePlaceholderID === placeholder.pagePlaceholderID);

          const newCategorization = newContent.enquiryContent.question.categorization;
          const oldCategorization = currentPlaceholder?.contents[0].enquiryContent.question.categorization;
          const newFromContent = newContent.enquiryContent.question.categorization.alternatives.find((x) => x.contentID === fromContentID);
          const newTargetContent = newContent.enquiryContent.question.categorization.alternatives.find((x) => x.contentID === targetContentID);
          const oldFromContent = currentPlaceholder?.contents[0].enquiryContent.question.categorization.alternatives.find((x) => x.contentID === fromContentID);
          const oldTargetcontent = currentPlaceholder?.contents[0].enquiryContent.question.categorization.alternatives.find((x) => x.contentID === targetContentID);
          if (newFromContent && newTargetContent && oldFromContent && oldTargetcontent) {
            oldFromContent.enquiryContent.question.categorization.alternatives = newFromContent.enquiryContent.question.categorization.alternatives;
            oldTargetcontent.enquiryContent.question.categorization.alternatives = newTargetContent.enquiryContent.question.categorization.alternatives;
          }

          if (oldCategorization) {
            oldCategorization.categorizationColumn = newCategorization.categorizationColumn;
            oldCategorization.categorizationCard = newCategorization.categorizationCard;
          }
        });
      })
      .catch((e) => {
        console.error('Something went wrong ' + e);
      });
  };
}
