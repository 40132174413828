import Button from '@mui/material/Button/Button';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../store/store';
import CircularLoading from '../../../components/loading/circularLoading';
import UnsplashPhoto from './UnsplashPhoto';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '@mui/material/styles';

interface IUnsplashContentProps {
  onSelect: (src: string, altText?: string) => void;
}
export default observer(function UnsplashContent({ onSelect }: IUnsplashContentProps) {
  const { unsplashStore } = useStore();
  const { unsplashData, loading, closeUnsplash, getNextPage } = unsplashStore;
  const theme = useTheme();

  const useStyles = createUseStyles((theme: Theme) => ({
    Content: {
      overflow: 'auto',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      color: 'inherit',
    },
    SecondaryButton: {
      height: 56,
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
    imageList: {
      display: 'flex',
      flexFlow: 'column wrap',
      '.item:nth-child(3n+1)': { order: 1 },
      '.item:nth-child(3n+2)': { order: 2 },
      '.item:nth-child(3n)': { order: 3 },
      '&, &:before, &:after': {
        content: '',
        flexBasis: '100%',
        width: 0,
        order: 2,
      },
    },
    masonryBrick: {
      flex: 'auto',
      height: 250,
      minWidth: 150,
      maxWidth: 400,
      margin: '0 16px 16px 0',
      '&:nth-child(4n+1)': { width: 250 },
      '&:nth-child(4n+1):nth-child(4n+2)': { width: 325 },
      '&:nth-child(4n+1):nth-child(4n+3)': { width: 180 },
      '&:nth-child(4n+1):nth-child(4n+4)': { width: 380 },
    },
    loadMore: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(4),
    },
  }));
  const classes = useStyles({ theme });

  return (
    <>
      <div className={classes.Content} style={{ overflowX: 'hidden' }}>
        {loading && <CircularLoading loadingText='' />}
        <div style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
          {!loading && (
            <>
              {unsplashData?.map((photo, index) => (
                <div key={photo.urls.small + index} className={classes.masonryBrick}>
                  <UnsplashPhoto key={photo.id} photo={photo} index={index} onClose={closeUnsplash} onSelect={onSelect} />
                </div>
              ))}
            </>
          )}
        </div>
        {unsplashData.length > 0 && (
          <div className={classes.loadMore}>
            <Button variant='newSecondary' endIcon={<ArrowDownwardIcon />} onClick={getNextPage}>
              LOAD MORE
            </Button>
          </div>
        )}
      </div>
    </>
  );
});
