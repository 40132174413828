import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageSoundProperty from '../../styleProperties/PageSoundProperty';
import { useStore } from '../../../store/store';
import { DynamicImageField, DynamicTextField } from '../../../components/properties/Properties';
import { runInAction } from 'mobx';
import PageCompletionRequirements from '../../../components/properties/requiermentProperties/PageCompletionRequirements';
import Divider from '@mui/material/Divider/Divider';
import { TPropertyValueView } from '../../../services/models/PropertyViewModel';

export interface IBackgroundStyle {
  backgroundImage?: string;
  backgroundSize?: EBackgroundSize;
  backgroundPosition?: string;
  backgroundColor?: string;
}

export enum EBackgroundSize {
  contain = 'contain',
  cover = 'cover',
}

export default observer(function DashboardPageProperties() {
  const theme = useTheme();
  const { courseStore } = useStore();
  const { courseViewModel, coursePageTempTitle, pageProperties } = courseStore;
  const [titleObj, setTitleObject] = useState<TPropertyValueView | undefined>(pageProperties?.descriptions.items.title ?? undefined);

  const handleOnTitleChange = (s: string) => {
    if (courseViewModel && pageProperties) {
      runInAction(() => {
        courseViewModel.pages.items[pageProperties.coursePageID].data.title.value = s;
        setTitleObject((title) => (title ? { ...title, option: { ...title.option, value: s } } : undefined));
      });
    }
  };

  useEffect(() => {
    if (coursePageTempTitle && pageProperties) {
      runInAction(() => {
        setTitleObject((title) => (title ? { ...title, option: { ...title.option, value: coursePageTempTitle } } : undefined));
      });
    }
  }, [coursePageTempTitle, pageProperties]);

  useEffect(() => {
    if (pageProperties) {
      runInAction(() => {
        setTitleObject(pageProperties.descriptions.items.title);
      });
    }
  }, [pageProperties]);

  if (!titleObj && pageProperties !== undefined) {
    setTitleObject(pageProperties.descriptions.items.title);
  }

  if (!pageProperties || !titleObj) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 12, padding: 16, gap: 16, background: theme.palette.bgFive.main, flex: '1 1 auto', boxSizing: 'border-box' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Page Properties</Typography>
      <DynamicImageField property={pageProperties.descriptions.items.avatar} />
      <DynamicTextField label textVariant={'body1'} property={titleObj} style={{ width: '100%' }} onBlur={handleOnTitleChange} onChange={handleOnTitleChange} />
      <DynamicTextField
        label
        textVariant={'body1'}
        multiline
        fullWidth
        rows={5}
        property={pageProperties.descriptions.items.note}
        placeholder={'Page Note'}
        style={{ width: '100%', marginBottom: 16 }}
      />
      <Divider />
      <PageSoundProperty audio={pageProperties.sound} accordion={false} />
      <PageCompletionRequirements completionRequirements={pageProperties.requirements.items.completionRequirements} />
    </div>
  );
});
