import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ImageUpload from '../imageEditor/ImageUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { TPropertyValueView } from '../../services/models/PropertyViewModel';
import { useStore } from '../../store/store';
import { styled, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import IconButton from '@mui/material/IconButton/IconButton';
import { fromTimestamp, toTimestamp } from '../../utils/convertions';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Menu from '@mui/material/Menu/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { runInAction } from 'mobx';
import { createUseStyles } from 'react-jss';
import ColorPicker from '../color/ColorPicker';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import { Editor } from '@tinymce/tinymce-react';

/*
En property har muligheten til å bli stylet på to måter. 
Enten via style tag som går til containeren til selve propertien,
Eller via "compoentsStyle", som feks vil være "dropdownStyle" eller "numberStyle" som styler en spesifik del av propertien.
*/

export interface IDescriptionProps {
  textVariant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | undefined;
  select?: boolean;
  naked?: boolean;
  multiline?: boolean;
  label?: boolean;
  children?: React.ReactNode;
}

export interface IImgSize {
  width: number;
  height: number;
}

export interface IPropertyFieldProps extends IDescriptionProps {
  onBlur?: (s: string) => void;
  property: TPropertyValueView;
  updateOnChange?: boolean;
  type?: string;
  fontSize?: string;
  autofocus?: boolean;
  style?: React.CSSProperties;
  backgroundColor?: string;
  borderRadius?: number;
  inputColor?: string;
  placeholder?: string;
  rows?: number;
  fullWidth?: boolean;
  imgSize?: IImgSize;
  onChange?: (e: string) => void;
  onChangePromise?: (e: string) => Promise<boolean>;
}
export interface IPercentFieldProp extends IPropertyFieldProps {
  min?: number;
  max?: number;
}
export interface IPropertyInputStyle {
  label?: React.CSSProperties;
  input?: React.CSSProperties;
}
export interface IDropdownFieldProps extends IPropertyFieldProps {
  dropdownStyle?: IPropertyInputStyle;
}
export interface INumberFieldProps extends IPropertyFieldProps {
  numberStyle?: IPropertyInputStyle;
  inputColor?: string;
}

export interface INewPropertyProps extends IPropertyFieldProps {
  isStatic: boolean;
}

/* export function Property({ property, children, textVariant, select, label, naked, multiline, isStatic }: INewPropertyProps) {
  const NewProperty = isStatic ? StaticTextField : DynamicTextField;

  const Property = () => {
    return React.createElement(
      NewProperty,
      {
        property: property,
        textVariant: textVariant,
        select: select,
        naked: naked,
        label: label,
        multiline: multiline,
      },
      children,
    );
  };
  return (
    <>
      <Property />
    </>
  );
} */

export function DynamicTextField({
  property,
  children,
  select,
  naked,
  multiline,
  updateOnChange,
  style,
  inputColor,
  label,
  fontSize,
  type = 'text',
  onChange,
  onBlur,
  placeholder,
  autofocus,
  rows,
}: IPropertyFieldProps) {
  const { ownerStore, courseStore } = useStore();
  const { updateCourseOption } = ownerStore;
  const { selectedCourseId } = courseStore;
  const [value, setValue] = useState(property.option.value !== null ? property.option.value : '');

  const updateOption = (e: any) => {
    updateCourseOption(selectedCourseId, property, e.target.value);
    runInAction(() => {
      let value = e.target.value;
      if (type === 'number') value = Math.floor(value);
      property.option.value = value;
    });
  };

  const handleOnChange = (e: any) => {
    if (updateOnChange) updateOption(e);
    let value = e.target.value;
    if (type === 'number') {
      value = Math.max(0, Math.floor(value));
    }
    setValue(value);
    if (onChange !== undefined) onChange(value);
  };

  const handleEnter = (e: any) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      updateOption(e);
    }
  };

  const handleOnBlur = (e: any) => {
    if (!updateOnChange) {
      updateOption(e);
    }
    if (onBlur !== undefined) {
      let value = e.target.value;
      if (type === 'number') value = Math.floor(value);
      onBlur(value);
    }
  };

  useEffect(() => {
    setValue(property.option.value !== null ? property.option.value : '');
  }, [property]);

  return (
    <div style={style}>
      {label && <Typography variant='body1'>{property.caption}</Typography>}
      <TextField
        autoFocus={autofocus}
        variant={naked ? 'standard' : 'outlined'}
        size={select ? 'small' : undefined}
        hiddenLabel={naked}
        placeholder={placeholder !== undefined ? placeholder : property.option.value}
        value={value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={(e) => {
          if (e.currentTarget.type === 'text') {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          }
        }}
        onKeyDown={handleEnter}
        disabled={property.option.disabled || property.propertyValueState == 'disabled' ? true : false}
        select={select}
        multiline={multiline}
        rows={rows}
        maxRows={rows ? undefined : 10}
        fullWidth
        type={type}
        InputProps={{ style: { fontSize: fontSize ? fontSize : undefined }, spellCheck: 'false', inputProps: { min: 0 } }}
        sx={{ input: { color: inputColor } }}
      >
        {children}
      </TextField>
    </div>
  );
}
export function DynamicTextFieldTitle({ property, children, select, naked, multiline, label, type = 'text', onBlur }: IPropertyFieldProps) {
  return (
    <DynamicTextField property={property} key={type} select={select} naked={naked} label={label} multiline={multiline} fontSize={'1.25rem'} autofocus={true} onBlur={onBlur}>
      {children}
    </DynamicTextField>
  );
}

export function DynamicTextFieldDescription({ property, children, select, naked, multiline, label, type = 'text', onBlur }: IPropertyFieldProps) {
  return (
    <DynamicTextField property={property} key={type} select={select} naked={naked} label={label} multiline={multiline} fontSize={'0.875rem'} autofocus={true} onBlur={onBlur}>
      {children}
    </DynamicTextField>
  );
}

export function DynamicTextFieldLabel({ property, children, select, multiline, style, textVariant, label, type = 'text', onBlur, placeholder }: IPropertyFieldProps) {
  return (
    <>
      <div style={{ ...style, width: '100%' }}>
        {label && (
          <Typography variant={textVariant} style={{ marginRight: 30, alignSelf: 'top' }}>
            {property.caption}
          </Typography>
        )}
        <DynamicTextField property={property} naked select={select} onBlur={onBlur} multiline={multiline} type={type} placeholder={placeholder} />
        {children}
      </div>
    </>
  );
}

export function DynamicTextFieldStyled({
  onBlur,
  property,
  children,
  select,
  naked,
  multiline,
  updateOnChange,
  style,
  backgroundColor,
  borderRadius,
  inputColor,
  label,
  rows,
  type = 'text',
  textVariant = 'body1',
  fullWidth = false,
  placeholder,
  onChange,
  onChangePromise,
}: IPropertyFieldProps) {
  const { ownerStore, courseStore } = useStore();
  const { updateCourseOption } = ownerStore;
  const { selectedCourseId } = courseStore;
  const [value, setValue] = useState(property.option.value !== null ? property.option.value : '');

  const updateOption = (e: any) => {
    updateCourseOption(selectedCourseId, property, e.target.value);
    runInAction(() => {
      let value = e.target.value;
      if (type === 'number') value = Math.floor(value);
      property.option.value = value;
    });
  };

  const handleOnChange = (e: any) => {
    let value = e.target.value;
    if (type === 'number') {
      value = Math.max(0, Math.floor(value));
    }
    if (onChangePromise !== undefined) {
      onChangePromise(property.option.value).then((result) => {
        if (result) {
          if (onChange !== undefined) onChange(value);
          if (updateOnChange) updateOption(e);
          setValue(value);
        }
      });
    } else {
      if (onChange !== undefined) onChange(value);
      if (updateOnChange) updateOption(e);
      setValue(value);
    }
  };

  const handleEnter = (e: any) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      updateOption(e);
    }
  };

  const handleOnBlur = (e: any) => {
    if (!updateOnChange) {
      updateOption(e);
    }
    if (onBlur !== undefined) {
      let value = e.target.value;
      if (type === 'number') value = Math.floor(value);
      onBlur(value);
    }
  };

  useEffect(() => {
    setValue(property.option.value !== null ? property.option.value : '');
  }, [property]);

  let flexStyle: any = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
  if (fullWidth || fullWidth === undefined) flexStyle = {};

  const StyledTextField = useMemo(
    () =>
      styled((props: TextFieldProps) => <TextField {...props} />)(({ theme }) => ({
        '& .MuiInputBase-root': {
          border: '0px solid red',
          borderRadius: borderRadius !== undefined ? borderRadius : 6,
          backgroundColor: backgroundColor !== undefined ? backgroundColor : theme.palette.bgFour.main,
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&:focus fieldset': {
            borderColor: 'transparent',
          },
          '&:active fieldset': {
            borderColor: 'transparent',
          },
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent',
          },
        },
      })),
    [backgroundColor, borderRadius],
  );
  return (
    <>
      <div style={{ ...style, ...flexStyle }}>
        {label && <Typography variant={textVariant}>{property.caption}</Typography>}
        <StyledTextField
          value={value}
          variant={naked ? 'standard' : 'outlined'}
          size={select ? 'small' : undefined}
          hiddenLabel={naked}
          placeholder={placeholder !== undefined ? placeholder : property.option.value}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyDown={handleEnter}
          disabled={property.option.disabled || property.propertyValueState == 'disabled' ? true : false}
          select={select}
          multiline={multiline}
          rows={rows}
          fullWidth={fullWidth}
          type={type}
          InputProps={{ style: { fontSize: select ? '0.875rem' : undefined } }}
          sx={{ input: { color: inputColor, width: 90 } }}
        >
          {children}
        </StyledTextField>
      </div>
    </>
  );
}

interface IDynamicEditorFieldProps extends IPropertyFieldProps {
  defaultColor?: string;
}

export function DynamicEditorField({ property, style, backgroundColor, label, textVariant = 'body1', fullWidth = false, placeholder, defaultColor, onBlur }: IDynamicEditorFieldProps) {
  const { propertyStore } = useStore();
  const { setPropertyTimer } = propertyStore;
  const theme = useTheme();

  const timeUpdateServer = 1000;
  let timeout: NodeJS.Timeout | undefined = undefined;

  const editorRef = useRef<any>(null);
  const darkEditor = `${process.env.PUBLIC_URL}/EditorSkins/DarkEditor/`;

  const HandleUpdateOption = () => {
    const innerHTML: string = editorRef.current.getContent();
    setPropertyTimer(property, innerHTML);
    property.option.value = innerHTML;

    if (timeout == undefined) {
      timeout = setTimeout(() => {
        UpdateOption();
      }, timeUpdateServer);
    }
  };

  const UpdateOption = () => {
    setPropertyTimer(property, property.option.value);
    timeout = undefined;

    if (onBlur !== undefined) {
      onBlur(property.option.value);
    }
  };

  const handleEditorInit = () => {
    if (editorRef.current && property.option.value.length <= 0 && defaultColor !== undefined) {
      editorRef.current.execCommand('ForeColor', false, defaultColor);
      editorRef.current.execCommand('Bold');
    }
  };

  let flexStyle: any = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
  if (fullWidth || fullWidth === undefined) flexStyle = {};

  return (
    <>
      <div style={{ ...style, ...flexStyle }}>
        {label && <Typography variant={textVariant}>{property.caption}</Typography>}
        <div style={{ backgroundColor: backgroundColor !== undefined ? backgroundColor : theme.palette.bgFour.main }}>
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/TinyMCE/tinymce.min.js'}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={property.option.value}
            onEditorChange={HandleUpdateOption}
            init={{
              placeholder: placeholder !== undefined ? placeholder : property.option.value,
              height: 200,
              skin_url: darkEditor,
              skin: 'DarkEditor',
              preview_styles: 'font-size font-weight',
              menubar: false,
              image_advtab: true,
              toolbar: ['bold italic underline strikethrough forecolor backcolor fontsize'],
              statusbar: false,
              font_size_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
              inline: false,
              fixed_toolbar_container: '#newToolboxPos',
              //toolbar_persist: isEditorActive && isContentSelected && showToolbar,
              toolbar_persist: false,
              forced_root_block: 'div',
              setup: function (editor) {
                editor.on('blur', function () {
                  UpdateOption();
                });

                document.getElementById(editor.id)?.setAttribute('data-editor', 'true'); //Denne brukes i pageStore deleteContent for å sjekke om aktive elementet er editoren. Da kan man ikke slette.

                editorRef.current = editor;
              },
              init_instance_callback: handleEditorInit,
            }}
          />
        </div>
      </div>
    </>
  );
}

interface IFeedbackTextField {
  property: TPropertyValueView;
  defaultColor: string;
}
export function FeedbackTextField({ property, defaultColor }: IFeedbackTextField) {
  const theme = useTheme();

  return (
    <>
      <DynamicEditorField
        property={property}
        label
        borderRadius={0}
        backgroundColor={theme.palette.bgOne.main}
        multiline
        fullWidth
        rows={2}
        defaultColor={defaultColor}
        placeholder='This is optional.&#10;You can write feedback here.'
      />
    </>
  );
}

export function DynamicPercentFieldStyled({
  property,
  children,
  select,
  naked,
  multiline,
  updateOnChange,
  style,
  backgroundColor,
  borderRadius,
  inputColor,
  label,
  rows,
  type = 'text',
  textVariant = 'body1',
  fullWidth = false,
  placeholder,
  min = 0,
  max = 100,
}: IPercentFieldProp) {
  const { ownerStore, courseStore } = useStore();
  const { updateCourseOption } = ownerStore;
  const { selectedCourseId } = courseStore;

  const UpdateOption = (e: any) => {
    let value = e.target.value;

    //remove everything but numbers
    value = value.replace(/[^0-9]/g, '');

    if (value > max) {
      value = max;
    } else if (value < min) {
      value = min;
    }

    updateCourseOption(selectedCourseId, property, value);
    property.option.value = value;
  };

  const flexStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

  const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} />)(({ theme }) => ({
    '& .MuiInputBase-root': {
      border: '0px solid red',
      borderRadius: borderRadius !== undefined ? borderRadius : 6,
      backgroundColor: backgroundColor !== undefined ? backgroundColor : theme.palette.bgFour.main,
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&:focus fieldset': {
        borderColor: 'transparent',
      },
      '&:active fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  }));
  return (
    <>
      <div style={{ ...style, ...flexStyle }}>
        {label && <Typography variant={textVariant}>{property.caption}</Typography>}
        <StyledTextField
          variant={naked ? 'standard' : 'outlined'}
          size={'small'}
          hiddenLabel={naked}
          placeholder={placeholder !== undefined ? placeholder : property.option.value}
          defaultValue={property.option.value}
          onBlur={!updateOnChange ? (e) => UpdateOption(e) : () => void 0}
          onChange={updateOnChange ? (e) => UpdateOption(e) : () => void 0}
          disabled={property.option.disabled || property.propertyValueState == 'disabled' ? true : false}
          select={select}
          multiline={multiline}
          rows={rows}
          fullWidth={fullWidth}
          type={type}
          InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment>, style: { fontSize: '0.875rem' } }}
          sx={{ input: { color: inputColor, width: 50 } }}
        >
          {children}
        </StyledTextField>
      </div>
    </>
  );
}

//Takes seconds or time as hh:mm:ss and converts to hh:mm:ss
export function DynamicTimeField({ property, children, select, naked, multiline, style, inputColor, label, fontSize, onBlur, autofocus, fullWidth }: IPropertyFieldProps) {
  const { propertyStore } = useStore();
  const { setDesignPropertyTimer } = propertyStore;
  const [timeValue, setTimeValue] = useState(toTimestamp(property.option.value));
  const inputRef = useRef<HTMLInputElement>(null);

  const UpdateOption = (e: any) => {
    let time = e.target.value;
    if (time.includes(':')) {
      time = fromTimestamp(time);
    }

    setDesignPropertyTimer(property, time);
    runInAction(() => {
      property.option.value = time;
    });
  };

  const handleEnter = (e: any) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      UpdateOption(e);
    }
  };

  const handleOnBlur = (e: any) => {
    if (e.target.value.includes(':')) {
      const time = e.target.value.split(':');

      let seconds: number = parseInt(time[time.length - 1]);

      for (let i = time.length - 2; i >= 0; i--) {
        seconds = seconds + time[i] * 60 ** (time.length - 1 - i);
      }
      setTimeValue(toTimestamp(seconds.toString()));
    } else {
      setTimeValue(toTimestamp(e.target.value));
    }

    UpdateOption(e);
    if (onBlur) {
      onBlur(e.target.value);
    }
  };

  const handleOnChange = (e: any) => {
    if (e.target.value === null || e.target.value === '') {
      e.target.value = 0;
    }
    setTimeValue(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = timeValue;
    }
  }, [timeValue]);

  return (
    <>
      <div style={style}>
        {label && <Typography variant='body1'>{property.caption}</Typography>}
        <TextField
          ref={inputRef}
          value={timeValue}
          autoFocus={autofocus}
          variant={naked ? 'standard' : 'outlined'}
          size={'small'}
          hiddenLabel={naked}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          onKeyDown={handleEnter}
          disabled={property.option.disabled || property.propertyValueState == 'disabled' ? true : false}
          select={select}
          multiline={multiline}
          maxRows={10}
          fullWidth={fullWidth}
          type={'text'}
          InputProps={{ style: { fontSize: fontSize ? fontSize : undefined, paddingTop: 2 } }}
          sx={{ input: { color: inputColor, textAlign: 'center', paddingRight: '2px' } }}
        >
          {children}
        </TextField>
      </div>
    </>
  );
}

export function DynamicNumberField({ property, children, select, multiline, updateOnChange, label, style, numberStyle, inputColor }: INumberFieldProps) {
  return (
    <>
      <div style={{ ...style, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {label && (
          <Typography variant='body1' style={numberStyle?.label}>
            {property.caption}
          </Typography>
        )}
        <DynamicTextField property={property} naked={true} updateOnChange={updateOnChange} select={select} multiline={multiline} type={'number'} style={numberStyle?.input} inputColor={inputColor}>
          {children}
        </DynamicTextField>
      </div>
    </>
  );
}

export function DynamicNumberFieldMin({ property, children, select, multiline, updateOnChange, label, style, numberStyle, inputColor, onChange, onBlur }: INumberFieldProps) {
  return (
    <>
      <div style={{ ...style, width: '100%', display: 'grid', gridTemplateColumns: '75% 20%', gridTemplateRows: '1fr', gap: '0% 5%', justifyItems: 'stretch', alignItems: 'center' }}>
        {label && (
          <Typography variant='body1' style={numberStyle?.label}>
            {property.caption}
          </Typography>
        )}
        <DynamicTextField
          property={property}
          naked={true}
          updateOnChange={updateOnChange}
          select={select}
          multiline={multiline}
          type={'number'}
          inputColor={inputColor}
          onChange={onChange}
          onBlur={onBlur}
        >
          {children}
        </DynamicTextField>
      </div>
    </>
  );
}

export function StaticTextField({ property, textVariant: variant, children, label, style }: IPropertyFieldProps) {
  return (
    <>
      <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
        <Typography variant={variant} style={{ wordBreak: 'break-word', display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 400 }}>
          {label && <span style={{ fontWeight: 500 }}>{property.caption}: </span>}
          {property.option.value}
        </Typography>
        {children}
      </div>
    </>
  );
}
export function StaticDateField({ property, textVariant: variant, children, label, style }: IPropertyFieldProps) {
  return (
    <>
      <div style={{ ...style }}>
        <Typography variant={variant} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 400 }}>
          {label && <span style={{ fontWeight: 500 }}>{property.caption}: </span>}
          {property.option.text}
        </Typography>
        {children}
      </div>
    </>
  );
}

export function StaticImageField({ property, children, style }: IPropertyFieldProps) {
  const imageBaseURL = process.env.REACT_APP_ENV_BASEURL;
  let url = '';
  if (property.option.value) url = property.option.value.includes('https') ? property.option.value : 'https://' + imageBaseURL + '.norskinteraktiv.no/niasAPI' + property.option.value;

  return (
    <>
      <div style={style}>
        <img src={url} alt={property.caption} style={{ width: '100%', height: 200, objectFit: 'cover', objectPosition: 'center', borderRadius: 12 }} />
        {children}
      </div>
    </>
  );
}

export function DynamicImageField({ property, children, imgSize }: IPropertyFieldProps) {
  const imageBaseURL = process.env.REACT_APP_ENV_BASEURL;

  let url = '';
  if (property.option.value) url = property.option.value.includes('https') ? property.option.value : 'https://' + imageBaseURL + '.norskinteraktiv.no/niasAPI' + property.option.value;

  const useStyles = createUseStyles({
    background: {
      width: imgSize ? imgSize.width : '100%',
      height: imgSize ? imgSize.height : '200px',
      borderRadius: imgSize ? 0 : 12,
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      gridTemplateColumns: '1fr',
    },
    uploadContiner: {
      gridRowStart: 1,
      gridColumnStart: 1,
      opacity: url.length <= 0 ? 1 : 0,
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 1,
        background: 'rgba(0,0,0,0.4)',
      },
      '&:active': {
        background: 'rgba(0,0,0,0.6)',
      },
    },
    icon: {
      fontSize: imgSize ? imgSize.height * 0.8 : 120,
    },
  });
  const classes = useStyles();

  return (
    <>
      <ImageUpload propertyValueView={property}>
        {url.length > 1 ? (
          <>
            <div className={classes.background} style={{ position: 'relative' }}>
              <img
                src={url}
                alt={property.caption}
                style={{
                  width: imgSize ? 'unset' : '100%',
                  height: imgSize ? 'unset' : '200px',
                  maxWidth: imgSize ? imgSize.width : 'unset',
                  maxHeight: imgSize ? imgSize.height : 'unset',
                  objectFit: imgSize ? 'contain' : 'cover',
                  objectPosition: 'center',
                  borderRadius: imgSize ? 0 : 12,
                  gridRowStart: 1,
                  gridColumnStart: 1,
                }}
              />
              <div className={classes.uploadContiner}>
                <FileUploadIcon className={classes.icon} />
              </div>
            </div>
            {children}
          </>
        ) : (
          <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>Click to upload</div>
        )}
      </ImageUpload>
    </>
  );
}

export function DropdownField({ property, children, label, style, naked, dropdownStyle }: IDropdownFieldProps) {
  return (
    <>
      <div style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {label && (
          <Typography variant='body1' style={{ ...dropdownStyle?.label, marginRight: 30 }}>
            {property.caption}
          </Typography>
        )}
        <DynamicTextField property={property} select naked={naked} updateOnChange style={dropdownStyle?.input}>
          {property.options?.map((content) => (
            <MenuItem key={content.value} disabled={content.disabled} value={content.value}>
              {content.text}
            </MenuItem>
          ))}
        </DynamicTextField>
        {children}
      </div>
    </>
  );
}

export function DropdownFieldStyled({ onBlur, property, children, label, style, textVariant: variant, dropdownStyle }: IDropdownFieldProps) {
  return (
    <>
      <div style={{ ...style, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {label && (
          <Typography variant={variant} style={{ ...dropdownStyle?.label, marginRight: 30 }}>
            {property.caption}
          </Typography>
        )}
        <DynamicTextFieldStyled onBlur={onBlur} property={property} select updateOnChange>
          {property.options?.map((content) => (
            <MenuItem key={content.value} disabled={content.disabled} value={content.value} style={{ fontSize: '0.875' }}>
              {content.text}
            </MenuItem>
          ))}
        </DynamicTextFieldStyled>
        {children}
      </div>
    </>
  );
}

export function StaticCheckbox({ property, children, label, style }: IPropertyFieldProps) {
  const checked = property.option.value.toLowerCase() == 'true' ? true : false;
  return (
    <>
      <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={checked} />} label={label ? property.caption : ''} />
        </FormGroup>
        {children}
      </div>
    </>
  );
}

export function DynamicCheckbox({ property, children, label, style, onBlur }: IPropertyFieldProps) {
  const { propertyStore } = useStore();
  const { setDesignPropertyTimer } = propertyStore;

  const checked = property.option.text.toLowerCase() == 'true' ? true : false;

  const UpdateOption = (e: any) => {
    setDesignPropertyTimer(property, e.target.checked.toString());
    runInAction(() => {
      property.option.value = e.target.checked;
    });

    if (onBlur) {
      onBlur(String(e.target.checked));
    }
  };

  return (
    <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
      <FormGroup>
        <FormControlLabel control={<Checkbox defaultChecked={checked} onChange={UpdateOption} />} label={label ? property.caption : ''} sx={{ marginRight: '0px' }} />
      </FormGroup>
      {children}
    </div>
  );
}

export function DynamicColorPicker({ property, children, label, style, onBlur }: IPropertyFieldProps) {
  const { propertyStore } = useStore();
  const { setDesignPropertyTimer } = propertyStore;

  const UpdateOption = (aColor: string) => {
    setDesignPropertyTimer(property, aColor);

    runInAction(() => {
      property.option.value = aColor;
    });

    if (onBlur !== undefined) {
      onBlur(aColor);
    }
  };

  return (
    <>
      <div style={{ ...style, width: '100%' /* display: 'grid', gridTemplateColumns: '79% 21%', gridTemplateRows: '1fr', justifyItems: 'stretch' */ }}>
        {label && <Typography variant='body1'>{property.caption}</Typography>}
        <ColorPicker
          defaultColor={property.option.value}
          onCommitColor={UpdateOption}
          onColorChange={() => {
            return;
          }}
          showSwatches={false}
          isCompact
        />
        {children}
      </div>
    </>
  );
}

export function StaticAudioField({ name: audioName, url, autoPlay, loop, volume: volumeProperty, children, onDelete }: IAudioFieldProps) {
  const { propertyStore } = useStore();
  const { setProperty } = propertyStore;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLooping, setIsLooping] = useState(loop.option.value == 'True' ? true : false);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlay.option.value == 'True' ? true : false);
  const [volume, setVolume] = useState(0);
  const [time, setTime] = useState(0);
  const [timeString, setTimeString] = useState('00:00');
  const [name, setName] = useState(audioName.option.value);
  const theme = useTheme();
  const audioEl = React.createRef<HTMLAudioElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //const audioName = url.option.value.split('/').pop();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onPlayToggle = () => {
    if (audioEl.current) {
      if (audioEl.current.paused) {
        audioEl.current.play();
        setIsPlaying(true);
      } else {
        audioEl.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const onTimeCommitted = (event: any, aValue: number | number[]) => {
    if (audioEl.current) {
      audioEl.current.currentTime = ((aValue as number) / 100) * audioEl.current.duration;
    }
  };

  const onTimeChange = (event: Event, aValue: number | number[]) => {
    if (audioEl.current) {
      audioEl.current.pause();
      audioEl.current.currentTime = ((aValue as number) / 100) * audioEl.current.duration;
      setIsPlaying(false);
    }
  };

  const onVolumeChange = (event: Event, aValue: number | number[]) => {
    if (audioEl.current) {
      audioEl.current.volume = (aValue as number) / 100;
      setVolume(aValue as number);
    }
  };

  const onVolumeChangeCommitted = () => {
    if (audioEl.current) {
      setProperty(volumeProperty, Math.round(audioEl.current.volume * 100).toString());
    }
  };

  const onLoopChange = (e: any) => {
    if (audioEl.current) {
      setIsLooping(e.target.checked);
      audioEl.current.loop = e.target.checked;
      loop.option.value = e.target.checked;
      setProperty(loop, e.target.checked.toString());
    }
  };

  const onAutoPlayChange = (e: any) => {
    if (audioEl.current) {
      setIsAutoPlay(e.target.checked);
      autoPlay.option.value = e.target.checked;
      setProperty(autoPlay, e.target.checked.toString());
    }
  };

  const onEnded = () => {
    if (audioEl.current) {
      setIsPlaying(false);
      audioEl.current.currentTime = 0;
    }
  };

  const handleDeleteSound = () => {
    url.option.value = '';
    setProperty(url, '');

    if (onDelete) {
      onDelete();
    }
  };

  useEffect(() => {
    try {
      const v = parseFloat(volumeProperty.option.value);
      setVolume(v);
    } catch {
      console.log('volumeProperty is not a number');
    }
  }, []);

  useEffect(() => {
    const timeUpdate = (event: any) => {
      const time = (event.srcElement.currentTime / event.srcElement.duration) * 100;
      setTimeString(toTimestamp(event.srcElement.currentTime));
      setTime(isNaN(time) ? 0 : time);
    };
    if (audioEl.current) {
      audioEl.current.addEventListener('timeupdate', timeUpdate, { passive: false });
    }
    return () => {
      if (audioEl.current) {
        audioEl.current.removeEventListener('timeupdate', timeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    setName(audioName.option.value);
  }, [audioName.option.value]);

  const useStyles = createUseStyles((theme: Theme) => ({
    audioPlayer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0),
      boxSizing: 'border-box',
    },
    controlsContainer: {
      height: 30,
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      paddingRight: 10,
    },
    volumeContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 8,
      gap: 20,
    },
  }));
  const classes = useStyles({ theme });
  return (
    <>
      <audio ref={url.option.value.length > 5 ? audioEl : null} src={url.option.value} onEnded={onEnded} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ paddingLeft: 8, display: 'flex', alignItems: 'center', wordBreak: 'break-all' }}>
          <MusicNoteIcon />
          {name}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color='gray' onClick={handleOpenMenu}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.audioPlayer}>
        <div className={classes.controlsContainer}>
          <IconButton color='gray' onClick={onPlayToggle}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Slider color={'slider'} aria-label='Time slider' min={0} max={100} value={time} size={'small'} onChange={onTimeChange} onChangeCommitted={onTimeCommitted} />
          <span>{timeString}</span>
        </div>
        <div style={{ paddingLeft: 13 }}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={isAutoPlay} onChange={onAutoPlayChange} />} label={'Auto Play'} />
            <FormControlLabel control={<Checkbox checked={isLooping} onChange={onLoopChange} />} label={'Loop Audio'} />
          </FormGroup>
          <div className={classes.volumeContainer}>
            <VolumeDown />
            <Slider color={'slider'} aria-label='Volume slider' min={0} max={100} value={volume} size={'small'} onChange={onVolumeChange} onChangeCommitted={onVolumeChangeCommitted} />
            <VolumeUp />
          </div>
        </div>
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={handleDeleteSound}>Delete</MenuItem>
        {children}
      </Menu>
    </>
  );
}

interface IAudioFieldProps {
  name: TPropertyValueView;
  url: TPropertyValueView;
  autoPlay: TPropertyValueView;
  loop: TPropertyValueView;
  volume: TPropertyValueView;
  children?: React.ReactNode;
  onReplace?: () => void;
  onDelete?: () => void;
}

export function DynamicAudioField({ name, url, autoPlay, loop, volume, onReplace, onDelete }: IAudioFieldProps) {
  const replaceAudio = () => {
    if (onReplace !== undefined) {
      onReplace();
    }
  };

  return (
    <>
      <StaticAudioField name={name} url={url} autoPlay={autoPlay} loop={loop} volume={volume} onDelete={onDelete}>
        <MenuItem onClick={replaceAudio}>Replace</MenuItem>
      </StaticAudioField>
    </>
  );
}

//Denne marks typen er midlertidig frem til denne egenskapen blir laget på server.
interface IDynamicSliderProps extends IPropertyFieldProps {
  min: number;
  max: number;
  step: number;
  marks: {
    value: number;
    label: string;
  }[];
  onBlur?: (s: string) => void;
}

export function DynamicSliderField({ property, min, max, step, label, onBlur, marks }: IDynamicSliderProps) {
  const { propertyStore } = useStore();
  const { setDesignPropertyTimer } = propertyStore;
  const [sliderValue, setSliderValue] = useState(0);

  const UpdateOption = (aValue: number) => {
    setSliderValue(aValue);
    setDesignPropertyTimer(property, aValue.toString());

    runInAction(() => {
      property.option.value = aValue.toString();
    });

    if (onBlur !== undefined) {
      onBlur(aValue.toString());
    }
  };

  useEffect(() => {
    try {
      setSliderValue(parseInt(property.option.value));
    } catch {
      console.log('unable to parse property to int - DynamicSlider');
    }
  }, []);

  return (
    <>
      {label && <Typography variant='body1'>{property.caption}</Typography>}
      <Slider
        color={'slider'}
        value={sliderValue}
        aria-label='accordion-button-size-slider'
        min={min}
        max={max}
        step={step}
        size='small'
        marks={marks}
        valueLabelDisplay='auto'
        onChange={(e: Event, newValue) => UpdateOption(newValue as number)}
      />
    </>
  );
}
