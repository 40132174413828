import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/store';
import { Theme, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderIcon from '@mui/icons-material/Folder';
import TextField from '@mui/material/TextField/TextField';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import CustomPopper from '../../components/customPopper/CustomPopper';
import Paper from '@mui/material/Paper/Paper';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import { IPathObject } from '../../utils/TreeList';
import Divider from '@mui/material/Divider/Divider';
import { runInAction } from 'mobx';
import { createUseStyles } from 'react-jss';

interface IMediaLibraryFolderItemProps {
  folder: IPathObject;
  parentDeleteChild?: (child: IPathObject) => void;
  setSelectedFolder: (s: string) => void;
}

//TODO: Endre slik at man kan trykke på hele "mappen" ikke bare teksten for å lage ny mappe
export default observer(function MediaLibraryFolderItem({ folder, parentDeleteChild, setSelectedFolder }: IMediaLibraryFolderItemProps) {
  const { mediaLibraryStore } = useStore();
  const { createNewFolder, tryDeleteFolder, deleteFolder, renameFileFolder, currentFolderPath } = mediaLibraryStore;
  const divRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState(folder.name);

  const [list, setList] = React.useState<IPathObject[]>(folder.children);

  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<null | HTMLElement>(null);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(event.currentTarget);
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const enableEditMode = () => {
    setEditMode(true);
    handleClose();
  };

  const handleCreateNewFolder = async () => {
    const newFolder = await createNewFolder(folder);
    if (newFolder != undefined) {
      const newList = list.concat(newFolder);
      setList(newList);
      folder.children = newList;
    }
    handleClose();
  };

  const handleExpand = () => {
    runInAction(() => {
      folder.expanded = !expanded;
    });
    setExpanded(!expanded);
  };

  const handleTryDeleteFolder = async () => {
    const response = await tryDeleteFolder(folder);
    if (response) {
      deleteFolder(folder);
      if (parentDeleteChild != undefined) {
        parentDeleteChild(folder);
      }
    } else {
      setPopperOpen(true);
    }
    setContextMenu(null);
  };

  const handleDeleteFolder = async () => {
    deleteFolder(folder);
    if (parentDeleteChild != undefined) {
      parentDeleteChild(folder);
    }
    setPopperOpen(false);
  };

  const handleDeleteChild = (child: IPathObject) => {
    const tmpList = list.filter((x) => x.name !== child.name);
    setList(tmpList);
  };

  const updateTitle = (e: any) => {
    setTitle(e.target.value);
    renameFileFolder(e.target.value, undefined, folder);
    setEditMode(false);
  };

  const handleSubmit = (e: any) => {
    if (e.charCode == 13) {
      updateTitle(e);
    }
  };

  const theme = useTheme();

  useEffect(() => {
    setTitle(folder.name);
    setList(folder.children);
  }, [folder]);

  const useStyles = createUseStyles((theme: Theme) => ({
    accordion: {
      marginLeft: 20,
      overflow: 'hidden',
    },
    folderItem: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.bgFour.main,
      },
    },
  }));

  const classes = useStyles({ theme });

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: theme.spacing(1) }}>
        <div
          className={classes.folderItem}
          style={{ backgroundColor: currentFolderPath == folder.path ? theme.palette.bgFour.light : 'transparent' }}
          onClick={() => setSelectedFolder(folder.path)}
          onDoubleClick={handleExpand}
          onContextMenu={handleContextMenu}
        >
          <span style={{ width: 48, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: theme.spacing(1) }} onClick={handleExpand}>
            {list.length > 0 && <>{expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>}
            <FolderIcon />
          </span>
          {!editMode ? (
            <span style={{ userSelect: 'none' }}>{title}</span>
          ) : (
            <TextField defaultValue={title} id='standard-basic' hiddenLabel variant='standard' onBlur={updateTitle} onKeyPress={handleSubmit} />
          )}
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <MenuItem onClick={handleCreateNewFolder}>New Sub Folder</MenuItem>
            <MenuItem onClick={enableEditMode}>Rename</MenuItem>
            <Divider />
            <MenuItem onClick={handleTryDeleteFolder}>Delete</MenuItem>
          </Menu>

          <CustomPopper open={popperOpen} anchorEl={popperAnchorEl}>
            <Paper elevation={5} sx={{ background: theme.palette.bgFour.main, padding: theme.spacing(2), zIndex: 10000 }}>
              <Typography variant='h6'>The folder is not empty!</Typography>
              <Typography variant='body2'>Are you sure you want to delete the folder?</Typography>
              <Box sx={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end' }}>
                <Button onClick={handleDeleteFolder}>Yes</Button>
                <Button onClick={() => setPopperOpen(false)}>No</Button>
              </Box>
            </Paper>
          </CustomPopper>
        </div>
        <div ref={divRef} className={classes.accordion} style={{ maxHeight: folder.expanded ? 20000 : 0 }}>
          {list.map((child, index) => (
            <MediaLibraryFolderItem key={index} folder={child} parentDeleteChild={handleDeleteChild} setSelectedFolder={setSelectedFolder} />
          ))}
        </div>
      </div>
    </>
  );
});
