import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Accordion from '../../components/accordion/Accordion';
import Modal from '../../components/modal/Modal';
import MediaLibrary from '../mediaLibrary/MediaLibrary';
import { TPageViewModel } from '../../services/models/PageViewModel';
import { IPropertyGroup } from '../../services/models/PropertyViewModel';
import { useStore } from '../../store/store';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import { DynamicAudioField } from '../../components/properties/Properties';
import Divider from '@mui/material/Divider/Divider';
import Button from '@mui/material/Button/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';
import AiVoice from '../../components/aiVoice/AiVoice';

interface IPageSoundPropertyProps {
  audio: IPropertyGroup;
  page?: TPageViewModel;
  accordion?: boolean;
}
interface IWrapperProps {
  wrapper: boolean;
  children: React.ReactNode;
}

export default observer(function PageSoundProperty({ audio, accordion = true }: IPageSoundPropertyProps) {
  const { mediaLibraryStore, propertyStore } = useStore();
  const { setDesignProperty } = propertyStore;
  const { openMediaLibraryFromEditor } = mediaLibraryStore;
  const theme = useTheme();
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const [isAIVoiceOpen, setIsAIVoiceOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [fileExist, setFileExist] = useState(!!audio.items.URL.option.value); // Ensure proper initial state
  const [isAdded, setIsAdded] = useState(audio.items.URL.option.value.trim() !== '');

  const handleSelectAudio = (aSrc: string, aFileName?: string) => {
    setIsMediaLibraryOpen(false);
    setIsAIVoiceOpen(false);
    setFileExist(true); // Update state immediately
    runInAction(() => {
      if (aFileName !== undefined) {
        audio.items.name.option.value = aFileName;
        setDesignProperty(audio.items.name, aFileName);
      }
      audio.items.URL.option.value = aSrc;
      setDesignProperty(audio.items.URL, aSrc);
    });
  };

  const handleAddAudio = () => {
    openMediaLibraryFromEditor();
    setIsMediaLibraryOpen(true);
    setDefaultOpen(true);
  };

  const handleDeleteAudio = () => {
    setIsAdded(false);
    setFileExist(false);
    runInAction(() => {
      audio.items.URL.option.value = ''; // Clear the audio URL
      setDesignProperty(audio.items.URL, '');
    });
  };

  useEffect(() => {
    setFileExist(!!audio.items.URL.option.value);
  });

  const Wrapper = ({ wrapper, children }: IWrapperProps) => {
    if (wrapper) {
      return (
        <Accordion title={'Audio'} isOpen={defaultOpen}>
          {children}
        </Accordion>
      );
    }
    return <>{children}</>;
  };

  if (!isAdded) {
    return (
      <>
        <Divider />
        <AddStyle
          styleName={'Audio'}
          addStyle={() => {
            setIsAdded(true);
            setDefaultOpen(true);
          }}
        />
      </>
    );
  }

  return (
    <>
      <Accordion title={'Audio'} isOpen={defaultOpen}>
        {fileExist ? (
          <DynamicAudioField
            name={audio.items.name}
            url={audio.items.URL}
            autoPlay={audio.items.autoPlay}
            loop={audio.items.loop}
            volume={audio.items.volume}
            onReplace={handleAddAudio}
            onDelete={handleDeleteAudio}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1), width: '100%' }}>
            <div style={{ width: 'min-content' }}>
              <Button color='dark' variant='contained' style={{ alignSelf: 'baseline', minWidth: 200 }} onClick={handleAddAudio}>
                Select audio file
              </Button>
            </div>
            <Typography variant='body1'>or</Typography>
            <div style={{ width: 'min-content' }}>
              <Button color='dark' variant='contained' style={{ alignSelf: 'baseline', minWidth: 200 }} onClick={() => setIsAIVoiceOpen(true)}>
                GENERATE AI VOICE
              </Button>
            </div>
          </div>
        )}
      </Accordion>

      <Modal
        isOpen={isMediaLibraryOpen}
        onClose={() => setIsMediaLibraryOpen(false)}
        title='Media Library'
        content={<MediaLibrary onSelect={handleSelectAudio} acceptedFiletypes={['.mp3', '.wav', '.ogg', '.m4a']} />}
      />
      <Modal isOpen={isAIVoiceOpen} onClose={() => setIsAIVoiceOpen(false)} title='AI Voice Generator' content={<AiVoice onSelect={handleSelectAudio} />} />
      <Divider />
    </>
  );
});
